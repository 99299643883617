import { useMemo } from 'react';
import { LinearProgress } from '@app/ui/progress';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { calculateSurveyProgress } from '@app/dynamic/utils';
import { QuestionnaireDynamicFormConfig } from '@app/questionnaire/types';
import { ApplicationDynamicFormConfig } from '@app/application/types';

export interface SurveyProgressProps extends GridRenderCellParams {
  config: ApplicationDynamicFormConfig | QuestionnaireDynamicFormConfig;
}

export const SurveyProgress = ({ row, config }: SurveyProgressProps) => {
  const progress = useMemo(() => calculateSurveyProgress(config.surveys, row), [row]);

  return <LinearProgress value={progress.progressTotal} />;
};
