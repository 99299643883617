import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { get } from '@app/app/utils/get';
import { isAnswered, pageTransformArrays, surveyTransformArrays } from '@app/dynamic/utils';
import { DynamicFormConfigElement, DynamicFormConfigSurvey } from '@app/dynamic/types';
import { DynamicFormElementType } from '@app/dynamic/constants';

import { QuestionnairePdf } from '@app/questionnaire/constants';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { questionnaireGetName } from '@app/questionnaire/components/pdf/pages/QuestionnairePdfCoverPage';
import { mainStyles } from '../styles';
import { PdfPagesTemplate } from './PdfPagesTemplate';
import { ApplicationPdf } from '../../../constants';
import { Application } from '../../../types/Application';
import { ApplicationPdfDocumentElement } from '../ApplicationPdfDocumentElement';
import { getName } from '../../../utils';

export interface PdfDocumentElement {
  config: DynamicFormConfigElement;
  value: any;
}

export interface PdfPagesProps {
  readonly type: ApplicationPdf | QuestionnairePdf;
  readonly data: Partial<Application | Questionnaire>;
  readonly surveys: DynamicFormConfigSurvey[];
  readonly translation?: 'application' | 'questionnaire';
}

export const PdfPages = ({ data, surveys, type, translation = 'application' }: PdfPagesProps) => {
  const { t } = useTranslation('common');
  const userName = translation === 'application' ? getName(data) : questionnaireGetName(data as Partial<Questionnaire>);

  const simpleSurveys = useMemo(() => {
    return surveys.reduce((acc, survey) => {
      const elements = pageTransformArrays(surveyTransformArrays(survey, data).pages, data).reduce<
        PdfDocumentElement[]
      >((elementsAcc, page) => {
        page.elements.forEach(element => {
          if (element?.meta?.pdf && element.meta.pdf[type]) {
            const value = get(data, element.name);

            if (!element.meta?.adminField || !isAnswered(get(data, element.meta?.adminField))) {
              if (isAnswered(value)) {
                if (element.type === DynamicFormElementType.toggleButtonGroup) {
                  const entries = Object.entries(value);

                  if (entries.length > 0) {
                    elementsAcc.push({
                      value: '',
                      config: {
                        ...element,
                        name: element.name,
                        type: DynamicFormElementType.toggleButtonGroup,
                      },
                    });
                    entries.forEach(([name, subValue]) => {
                      if (isAnswered(subValue)) {
                        elementsAcc.push({
                          value: subValue as boolean,
                          config: {
                            ...element,
                            name: `${element.name}.${name}`,
                            type: DynamicFormElementType.toggleButton,
                          },
                        });
                      }
                    });
                  }
                } else {
                  elementsAcc.push({
                    value,
                    config: element,
                  });
                }
              }
            }
          }
        });

        return elementsAcc;
      }, []);

      if (elements.length > 0) {
        acc.push({
          elements,
          name: survey.name,
        });
      }

      return acc;
    }, [] as { name: string; elements: PdfDocumentElement[] }[]);
  }, [data, surveys, t, type]);

  const photos = useMemo(() => data?.photos?.filter(photo => !!photo?.download?.file), [data?.photos]);

  return (
    <>
      <PdfPagesTemplate userName={userName} type={translation}>
        {simpleSurveys.map((survey, surveyIndex) => {
          const lastChild = surveyIndex === simpleSurveys.length - 1;

          return (
            <View wrap key={survey.name}>
              <Text style={[mainStyles.h2, styles.surveyGroupTitle]} fixed>
                {t(`${translation}.field.survey.option.${survey.name}.name`, data)}
              </Text>

              {survey.elements.map((element, indexField) => {
                const even = indexField % 2 !== 0;

                return (
                  <View wrap={false} key={element.config.name} style={even ? styles.even : undefined}>
                    <ApplicationPdfDocumentElement
                      config={{ ...element.config, translation: `${translation}.field` }}
                      value={element.value}
                    />
                    <View
                      style={!lastChild && indexField === survey.elements.length - 1 ? styles.surveyGroup : undefined}
                    />
                  </View>
                );
              })}
            </View>
          );
        })}
      </PdfPagesTemplate>

      {Array.isArray(photos) && photos.length > 0 && (
        <PdfPagesTemplate userName={userName} type={translation}>
          <View style={styles.photoBlock}>
            {photos.map(photo => (
              <View key={photo?.download?.url} style={styles.photoItem}>
                <Image style={styles.photoImage} src={photo?.download?.file} />
              </View>
            ))}
          </View>
        </PdfPagesTemplate>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  surveyGroup: {
    marginBottom: 30,
  },
  surveyGroupTitle: {
    marginBottom: 15,
  },
  even: {
    backgroundColor: '#FDFDFD',
  },
  photoBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: -8,
  },
  photoItem: {
    padding: 8,
    width: '50%',
    height: 348,
  },
  photoImage: {
    objectFit: 'cover',
    objectPosition: 'center',
    height: '100%',
    width: '100%',
  },
});
