import { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, Paper, Typography, InputLabel, Button } from '@mui/material';

import { get } from '@app/app/utils/get';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { Label } from '@app/ui/label';
import { Loader } from '@app/ui/loader';
import { useModal } from '@app/ui/modal';
import { USER_STATUS_COLOR, generalInfoItems } from '@app/user/constants';

import { ApplicationList } from './ApplicationList';
import { UploadForm } from '../upload/UploadForm';
import { ExportPdfDropdown } from '../pdf/ExportPdfDropdown';
import { UpdatePdfCoverModal } from '../pdf/UpdatePdfCoverModal';
import { Application } from '../../types/Application';
import { applicationGetOne } from '../../gql';
import { applicationConfig } from '../../configs';
import { getFieldsFromSurveys } from '../../utils/survey';

export const AdminApplicationItem = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const routerParams = useParams();
  const isMobile = useIsMobile();
  const { isOpen, close, show } = useModal();
  const { entity, ...params } = useParams<{ id?: string; entity: 'surrogates' | 'parent' }>();

  const id = Number(params.id);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(applicationConfig.surveys);

    return [...surveyFields, 'status', 'user.email'];
  }, []);

  const { data, loading } = useQuery<{ applicationGetOne?: Application }>(applicationGetOne(fields), {
    variables: { id },
  });
  const userName = `${data?.applicationGetOne?.firstName || ''} ${data?.applicationGetOne?.lastName || ''}`.trim();

  const breadcrumbs = useMemo(
    () => [
      {
        title: t(`user.page.adminList.title`),
        to: `/admin/${entity}`,
      },
      {
        title: userName,
      },
    ],
    [t, userName, entity],
  );

  const handleSurveyClick = useCallback(
    (survey: string) => navigate(`/admin/${routerParams.entity}/${id}/${survey}`),
    [id, routerParams],
  );

  return (
    <>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h1">{userName}</Typography>
        {data?.applicationGetOne?.status && (
          <Label
            sx={{ ml: 2, mt: 0.5, textTransform: 'capitalize' }}
            text={t(`application.field.status.option.${data?.applicationGetOne?.status}`)}
            color={USER_STATUS_COLOR[data?.applicationGetOne?.status]}
          />
        )}
      </Box>
      <Paper
        sx={{
          wordWrap: 'break-word',
          borderRadius: {
            md: '20px',
          },
          p: {
            xs: 2,
            sm: 3,
            lg: 5,
          },
          mb: 5,
        }}
        variant="outlined"
        elevation={0}
      >
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t('user.page.single.information')}
        </Typography>

        <Grid container direction={isMobile ? 'column' : 'row'}>
          {data?.applicationGetOne &&
            generalInfoItems.map((item, index) => {
              const name = item.split('.').pop();
              const lastChild = index === generalInfoItems.length - 1;

              return (
                <Fragment key={item}>
                  <Grid item xs md={lastChild ? 3 : 2}>
                    <InputLabel sx={{ mb: 1.5 }}>{t(`application.field.${name}.label`)}</InputLabel>
                    <div>{get(data.applicationGetOne || {}, item) || '-'}</div>
                  </Grid>

                  {!lastChild && (
                    <Divider
                      sx={{
                        my: {
                          xs: 1,
                          sm: 0,
                        },
                        mx: {
                          sm: 2,
                          lg: 3,
                        },
                      }}
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem
                    />
                  )}
                </Fragment>
              );
            })}
        </Grid>
      </Paper>
      <Box
        mb={3}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">{t('user.page.single.subtitle')}</Typography>

        <Box
          sx={{
            display: 'flex',
            gap: {
              xs: 2,
              md: 3,
            },
            flexWrap: 'wrap',
          }}
        >
          <Button className="filledTonal" color="primary" variant="contained" onClick={show}>
            {t('application.button.updateProfilePdf.name')}
          </Button>

          <ExportPdfDropdown applicationId={id} label={t('application.button.exportPdf.name')} arrow />
        </Box>
      </Box>
      {isOpen && <UpdatePdfCoverModal id={id} open={isOpen} onClose={close} />}

      {data?.applicationGetOne && <ApplicationList item={data.applicationGetOne} onClick={handleSurveyClick} isAdmin />}

      <Box mt={4}>
        <Typography variant="h2" mb={3}>
          {t('application.field.photos.label')}
        </Typography>
        <Paper
          sx={{
            p: {
              xs: 2,
              sm: 3,
              lg: 5,
            },
            borderRadius: '20px',
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography variant="h4" mb={3}>
            {t('application.field.photos.subtitle')}
          </Typography>

          {data?.applicationGetOne && <UploadForm applicationId={id} />}
        </Paper>
      </Box>
      <Loader isLoading={loading} />
    </>
  );
};
