import { useMemo } from 'react';
import { usePrevious } from 'react-use';
import { DataGrid, DataGridProps, GridColDef, GridRowModel } from '@mui/x-data-grid';

import { ReactComponent as SortIcon } from '@app/app/images/icons/icon-sort.svg';
import { ReactComponent as SortIconUp } from '@app/app/images/icons/icon-sort-up.svg';
import { ReactComponent as SortIconDown } from '@app/app/images/icons/icon-sort-down.svg';

import { TableNoRows } from './TableNoRows';
import { TableState } from '../types/TableState';

const rowsPerPageOptions: DataGridProps['pageSizeOptions'] = [30, 60, 100];

const slots: DataGridProps['slots'] = {
  columnUnsortedIcon: SortIcon,
  columnSortedAscendingIcon: SortIconUp,
  columnSortedDescendingIcon: SortIconDown,
  noRowsOverlay: TableNoRows,
};

const initialState: DataGridProps['initialState'] = { pagination: { paginationModel: { page: 0 } } };

export interface TableProps extends DataGridProps {
  //TODO OMIT
  readonly state: TableState;
  readonly onChangeState: (state: Partial<TableState>) => void;
}

export const Table = ({ state, onChangeState, rowCount, columns, ...props }: TableProps) => {
  const previousRowCount = usePrevious(rowCount);

  const patchedColumns = useMemo(
    () =>
      columns.map(column => {
        if (column?.field?.includes('.')) {
          return {
            ...column,
            valueGetter: (_value: string, row: GridRowModel, getterColumn: GridColDef) =>
              getterColumn.field.split('.').reduce((a: Record<any, any>, b: string) => a[b] || '', row),
          };
        }
        return column;
      }),
    [columns],
  );

  return (
    <DataGrid
      {...props}
      autoHeight
      pagination
      disableColumnMenu
      columnHeaderHeight={50}
      rowHeight={60}
      rowSpacingType="border"
      sortingMode="server"
      paginationMode="server"
      columns={patchedColumns}
      initialState={initialState}
      slots={slots}
      pageSizeOptions={rowsPerPageOptions}
      rowCount={rowCount || previousRowCount || 0}
      paginationModel={{
        page: state.page - 1,
        pageSize: state.limit,
      }}
      onPaginationModelChange={paginationModel =>
        onChangeState({ limit: paginationModel.pageSize, page: paginationModel.page + 1 })
      }
      sortModel={state.orderBy?.map(({ direction, field }) => ({ field, sort: direction }))}
      onSortModelChange={orderBy =>
        onChangeState({ orderBy: orderBy.map(({ sort, field }) => ({ field, direction: sort })) })
      }
    />
  );
};
