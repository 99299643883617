import { memo, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useParams } from 'react-router-dom';
import { Text as MuiText, TextProps as MuiTextProps } from './Text';

export interface DebouncedTextProps extends Omit<MuiTextProps, 'onChange'> {
  readonly onChange: (value?: string) => void;
  readonly wait?: number;
  readonly maxWait?: number;
  readonly changeFromLength?: number;
}

export const DebouncedText = memo(
  ({ onChange, value, changeFromLength = 3, wait = 750, maxWait = 100000, ...props }: DebouncedTextProps) => {
    const [innerValue, setInnerValue] = useState(value ?? '');
    const { entity } = useParams();

    // @ts-ignore
    const debouncedOnChange = useDebouncedCallback(onChange, wait, {
      maxWait,
    });

    useEffect(() => {
      setInnerValue('');
    }, [entity]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value);
      const newValue = e.target.value.length < 3 ? (value as string) : e.target.value;
      debouncedOnChange(newValue);
    }, []);

    return <MuiText {...props} onChange={handleChange} value={innerValue} />;
  },
);
