import { gql } from '@apollo/client';
import { convertArrayToGQL } from '@app/query/utils/graphql';

export const questionnaireGetManyWithPagination = (fields: string[]) => gql`
  query questionnaireGetManyWithPagination(
    $page: Int!
    $limit: Int!
    $orderBy: [OrderByDto]
    $search: String
    $status: QuestionnaireStatusEnum
    $createdAtLte: String
    $createdAtGte: String  
  ) {
    questionnaireGetManyWithPagination(
      page: $page
      limit: $limit
      orderBy: $orderBy
      status: $status
      search: $search
      createdAtLte: $createdAtLte
      createdAtGte: $createdAtGte
    ) {
      pagination {
        totalItems
      }
      rows {
          ${convertArrayToGQL(fields, true)}
      }
    }
  }
`;

export const questionnaireGetOne = (fields: string[]) => {
  return gql`
    query questionnaireGetOne($id: Int!) {
        questionnaireGetOne(id: $id) {
            ${convertArrayToGQL(fields, true)}
        }
    }
    `;
};

export const questionnaireUpdateOne = (fields: string[]) => {
  return gql`
    mutation questionnaireUpdateOne($input: QuestionnaireUpdateInput!) {
        questionnaireUpdateOne(input: $input) {
            ${convertArrayToGQL(fields, true)}
        }
    }
    `;
};

export const QUESTIONNAIRE_UPDATE_SURVEY_FLAG = gql`
  mutation questionnaireUpdateOneSurveyFlag($input: QuestionnaireUpdateSurveyFlagInput!) {
    questionnaireUpdateOneSurveyFlag(input: $input) {
      id
      flags
    }
  }
`;

export const QUESTIONNAIRE_SUBMIT = gql`
  mutation questionnaireSubmit($input: IdInput!) {
    questionnaireSubmit(input: $input) {
      id
      status
    }
  }
`;

export const QUESTIONNAIRE_SUBMIT_PHOTOS = gql`
  mutation questionnaireSubmitPhotos($input: IdInput!) {
    questionnaireSubmitPhotos(input: $input) {
      id
      isPhotosSubmitted
    }
  }
`;

export const QUESTIONNAIRE_UPDATE_ATTACH_PHOTOS = gql`
  mutation questionnaireUpdateOneAttachPhotos($input: QuestionnaireUpdateAttachPhotosInput!) {
    questionnaireUpdateOneAttachPhotos(input: $input) {
      id
      photos {
        id
        name
      }
    }
  }
`;
