import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { QuestionnairePdf, QuestionnaireSurveyEnum } from '../constants';
import cardImage from '../images/card-questionnaire-acknowledgement.jpg';

export const acknowledgement: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.acknowledgement,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation', 'firstName', 'partner.firstName'],
  },
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.acknowledgement}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.acknowledgement}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            variables: {
              time: '1-2',
            },
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'acknowledgementFirst',
      elements: [
        {
          name: 'acknowledgementForFirstParent',
          alias: 'acknowledgementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'acknowledgementSecond',
      visibleIf: ({ partner }) => !!partner,
      elements: [
        {
          name: 'acknowledgementForSecondParent',
          alias: 'acknowledgementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
      ],
    },
  ],
};
