import { ReactNode, useState, MouseEvent } from 'react';
import {
  Button,
  ButtonBase,
  Box,
  Grow,
  Paper,
  Popper,
  ButtonProps,
  PopperProps,
  ClickAwayListener,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { DropdownOption } from '../types';

export interface DropdownProps extends ButtonProps {
  readonly label: ReactNode;
  readonly options?: DropdownOption[];
  readonly arrow?: boolean;
  readonly popperWidth?: number | 'auto';
  readonly popperPlacement?: PopperProps['placement'];
}

export const Dropdown = ({
  options,
  label,
  children,
  arrow,
  color = 'primary',
  variant = 'contained',
  sx,
  popperWidth = 190,
  popperPlacement = 'bottom-end',
  className,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
      <span>
        <Button
          className={className}
          sx={[
            {
              minWidth: 0,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          onClick={handleToggle}
          color={color}
          variant={variant}
          endIcon={
            arrow && (
              <KeyboardArrowDownIcon
                sx={{
                  color: 'inherit',
                  transform: open ? 'rotate(180deg)' : '',
                }}
              />
            )
          }
        >
          {label}
        </Button>

        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={popperPlacement}
          transition
          style={{
            width: popperWidth,
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={400}>
              <Paper sx={{ py: 0.5, my: 0.5 }}>
                <Box
                  className="styled-scrollbar"
                  sx={{
                    py: options ? '' : 1,
                    px: options ? '' : 2,
                    maxHeight: 200,
                  }}
                >
                  {options ? (
                    options.map(option => (
                      <ButtonBase
                        key={option.id}
                        sx={{
                          justifyContent: 'flex-start',
                          py: 1,
                          px: 2,
                          my: 0.25,
                          width: '100%',

                          '&:hover': {
                            bgcolor: 'grey.200',
                          },
                        }}
                        onClick={() => {
                          if (option.onClick) {
                            option.onClick();
                          }
                          handleClose();
                        }}
                      >
                        {option.name}
                      </ButtonBase>
                    ))
                  ) : (
                    <>{children}</>
                  )}
                </Box>
              </Paper>
            </Grow>
          )}
        </Popper>
      </span>
    </ClickAwayListener>
  );
};
