import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthActions } from '@app/auth';
import { Loader } from '@app/ui/loader';
import { error, success } from '@app/snackbars';

export const SignUpConfirm = () => {
  const { t } = useTranslation('common');
  const { signUpConfirm } = useAuthActions();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      (async () => {
        const [code, usernameBase64] = token.split('.');

        try {
          const username = atob(usernameBase64);

          try {
            await signUpConfirm({ code, username });

            navigate('/sign-in', { state: { email: username } });

            success(t('auth.page.signUpConfirm.successMessage'));
          } catch (e) {
            navigate('/sign-in');
            error((e as Error).message || t('general.error.somethingWentWrong'));
          }
        } catch (e) {
          navigate('/sign-in');
          error(t('general.error.somethingWentWrong'));
        }
      })();
    }
  }, [token]);

  return <Loader isLoading />;
};
