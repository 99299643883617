import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import { error } from '@app/snackbars';
import { apolloClient } from '@app/query/configs/appoloClient';
import { getPdfFields } from '@app/application/utils/survey';
import { prependImageExifMeta } from '@app/application/utils/pdf';
import { QuestionnairePdf } from '../constants';
import { questionnaireConfig } from '../configs';
import { Questionnaire } from '../types/Questionnaire';
import { questionnaireGetOne } from '../gql';
import { QuestionnairePdfDocument } from '../components/pdf/QuestionnairePdfDocument';

export const useQuestionnaireGeneratePdf = () => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);

  const generatePdf = useCallback(
    async (id: number, type: QuestionnairePdf) => {
      setIsLoading(true);
      const fields = getPdfFields(questionnaireConfig.surveys, type, questionnaireConfig.pdf.coverPageFields);

      try {
        const response = await apolloClient.query<{ questionnaireGetOne: Questionnaire }>({
          query: questionnaireGetOne(fields),
          variables: { id },
        });

        const data = JSON.parse(JSON.stringify(response?.data?.questionnaireGetOne)) || {};

        await prependImageExifMeta([data?.avatar, ...(data?.photos || [])]);

        const blob = await pdf(<QuestionnairePdfDocument data={data} type={type} />).toBlob();

        await saveAs(blob, `${t(`questionnaire.export.option.${type}`)}.pdf`);
      } catch (e) {
        console.error(e);
        error(t('general.error.somethingWentWrong'));
      } finally {
        setIsLoading(false);
      }
    },
    [t],
  );

  return { generatePdf, isLoading };
};
