import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { AnnualHouseholdIncomeEnum, QuestionnairePdf, QuestionnaireSurveyEnum } from '../constants';
import cardImage from '../images/card-questionnaire-path-to-parenthood.jpg';

export const pathToParenthood: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.pathToParenthood,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation'],
  },
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.pathToParenthood}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.pathToParenthood}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            variables: {
              time: '2-3',
            },
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'needForPursuingSurrogacy',
      elements: [
        {
          noLabel: true,
          name: 'needForPursuingSurrogacy',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'consideredAdoption',
      elements: [
        {
          noLabel: true,
          name: 'consideredAdoption',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'sharedSurrogacyWithFamily',
      elements: [
        {
          noLabel: true,
          name: 'sharedSurrogacyWithFamily',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            length: 1000,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'annualHouseholdIncome',
      elements: [
        {
          name: 'annualHouseholdIncome',
          alias: 'annualHouseholdIncomeSpecify',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            enum: AnnualHouseholdIncomeEnum,
            withLockIcon: true,
          },
        },
      ],
    },
    {
      name: 'liquidableAssetsForSurrogacy',
      withLockIcon: true,
      elements: [
        {
          noLabel: true,
          name: 'liquidableAssetsForSurrogacy',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
        {
          name: 'liquidableAssetsDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ liquidableAssetsForSurrogacy }) =>
            !Boolean(liquidableAssetsForSurrogacy) && liquidableAssetsForSurrogacy !== null,
          props: {
            withLockIcon: true,
            length: 1000,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'accessAdditionalFunds',
      withLockIcon: true,
      elements: [
        {
          noLabel: true,
          name: 'accessAdditionalFunds',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
        {
          name: 'accessAdditionalFundsDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ accessAdditionalFunds }) => !Boolean(accessAdditionalFunds) && accessAdditionalFunds !== null,
          props: {
            withLockIcon: true,
            length: 1000,
            multiline: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
        },
      ],
    },
  ],
};
