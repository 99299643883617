import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, FormControl, Grid, Link, Typography } from '@mui/material';
import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import dayJs from 'dayjs';

import { useAuthActions } from '@app/auth';
import { error } from '@app/snackbars';
import { USA_STATES } from '@app/user/constants/usaStates';
import { Autocomplete, BooleanRadio, Date, EnumSelect, Password, Phone, ScrollToError, Text, yup } from '@app/formik';
import { Card } from '@app/ui/card';
import { extractValidationErrors } from '@app/query';
import {
  ApplicationStatusEnum,
  heightFtOptions,
  heightInOptions,
  numberOfDeliveriesOptions,
  numberOfSectionsOptions,
  ReferredFromEnum,
} from '@app/application/constants';
import { User } from '@app/user/types/User';
import { Loader } from '@app/ui/loader';

import { SignUpForm } from '../types/forms';
import { SignUpInfo } from './SignUpInfo';
import { AUTH_SIGN_UP } from '../gql';
import imgCard from '../images/img-card-sing-up.jpg';
import { TermsAndMessageConsentCheckboxes } from './TermsAndConsentCheckboxes';
import { isPasswordStrong } from '../utils';

const initialValues: Partial<SignUpForm> =
  process.env.NODE_ENV === 'development'
    ? {
        firstName: 'Roman',
        lastName: 'Confirm 2',
        phone: '+12312312312',
        isUsCitizen: true,
        residency: 'CA',
        email: 'rkritskiy+confirm2@mindk.com',
        password: '',
        referredFrom: ReferredFromEnum.google,
        heightFt: 5,
        heightIn: 11,
        weight: 200,
        dateOfBirth: '1995-08-22T19:51:43.000Z',
        numberOfDeliveries: 2,
        numberOfSections: 0,
        isConvictedOfFelony: false,
        isUterineMalformation: false,
        isSurrogateBefore: false,
        terms: true,
        messageConsent: true,
      }
    : {
        firstName: '',
        lastName: '',
        phone: '',
        isUsCitizen: undefined,
        residency: undefined,
        email: '',
        password: '',
        dateOfBirth: undefined,
        heightFt: undefined,
        heightIn: undefined,
        weight: undefined,
        numberOfDeliveries: undefined,
        numberOfSections: undefined,
        isConvictedOfFelony: undefined,
        isUterineMalformation: undefined,
        isSurrogateBefore: undefined,
        referredFrom: undefined,
        terms: false,
        messageConsent: false,
      };

export const SignUp = () => {
  const { t } = useTranslation('common');
  const { signOut, signIn, isLoading } = useAuthActions();
  const navigate = useNavigate();
  const [status, setStatus] = useState<ApplicationStatusEnum | undefined>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [signUp, { loading }] = useMutation<{ authSignUp: User }>(AUTH_SIGN_UP, { fetchPolicy: 'network-only' });

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required().max(50),
        lastName: yup.string().required().max(50),
        phone: yup
          .string()
          .required()
          .phone('US', true, String(t('general.validation.phone', { region: 'US' }))),
        isUsCitizen: yup.boolean().required(),
        residency: yup.string().required(),
        email: yup.string().required().email(),
        password: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, isPasswordStrong)
          .required(),
        dateOfBirth: yup.date().required(),
        heightFt: yup.number().required(),
        heightIn: yup.number().required(),
        weight: yup.number().required(),
        numberOfSections: yup.number().required(),
        numberOfDeliveries: yup.number().required(),
        isConvictedOfFelony: yup.string().required(),
        isUterineMalformation: yup.string().required(),
        isSurrogateBefore: yup.string().required(),
        referredFrom: yup.string().required(),
        terms: yup
          .boolean()
          .oneOf([true], String(t('general.validation.required')))
          .required(),
        messageConsent: yup.boolean(),
      }),
    [t],
  );

  const residencyOptions = useMemo(
    () => [{ id: 'notInUsa', name: t('application.field.residency.option.notInUsa') }, ...USA_STATES],
    [t],
  );

  const handleSubmit = useCallback(
    async (input: Partial<SignUpForm>, helpers: FormikHelpers<Partial<SignUpForm>>) => {
      if (!executeRecaptcha) {
        error(t('general.error.somethingWentWrong'));
      } else {
        try {
          const recaptchaToken = await executeRecaptcha('signUp');

          const data = await signUp({ variables: { input: { ...input, recaptchaToken } } });

          const applications = data?.data?.authSignUp?.applications;

          if (Array.isArray(applications)) {
            const newStatus = applications[0]?.status;

            if (newStatus !== ApplicationStatusEnum.disqualified) {
              try {
                await signOut();
                await signIn({ email: input.email as string, password: input.password as string });
              } catch (e) {
                console.error(e);
              }
            }

            setStatus(newStatus);
          }
        } catch (e) {
          const errors = extractValidationErrors(e as ApolloError);

          if (Object.keys(errors).length > 0) {
            helpers.setErrors(errors);
          } else {
            error((e as ApolloError).message || t('general.error.somethingWentWrong'));
          }
        }
      }
    },
    [t, executeRecaptcha],
  );

  return (
    <>
      <Loader isLoading={loading || isLoading} />

      <Card size="large" img={imgCard}>
        {!status && (
          <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {() => {
              return useMemo(
                () => (
                  <Form noValidate autoComplete="off">
                    <Typography variant="h1" gutterBottom>
                      {t('auth.page.signUp.title')}
                    </Typography>
                    <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
                      {t('auth.page.signUp.message')}
                    </Typography>

                    <Box mb={4}>
                      <Typography variant="h2" gutterBottom>
                        {t('auth.page.signUp.subtitle1')}
                      </Typography>

                      <Grid container spacing={{ xs: 2, sm: 3 }}>
                        <Grid item xs={12} sm={6}>
                          <Text required label={t('application.field.firstName.label')} name="firstName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Text required label={t('application.field.lastName.label')} name="lastName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Phone
                            required
                            name="phone"
                            label={t('application.field.phone.label')}
                            description={t('application.field.phone.description')}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <BooleanRadio
                            row
                            required
                            label={t('application.field.isUsCitizen.label')}
                            name="isUsCitizen"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Autocomplete
                            required
                            label={t('application.field.residency.label')}
                            name="residency"
                            options={residencyOptions}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Text required label={t('user.field.email.label')} name="email" type="email" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Password
                            required
                            label={t('user.field.password.label')}
                            description={t('general.validation.passwordStrong')}
                            name="password"
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Typography variant="h2" gutterBottom>
                        {t('auth.page.signUp.subtitle2')}
                      </Typography>
                      <Grid container spacing={{ xs: 2, sm: 3 }}>
                        <Grid item xs={12} sm={6}>
                          <Date
                            required
                            disableManual
                            name="dateOfBirth"
                            openTo="year"
                            label={t('application.field.dateOfBirth.label')}
                            maxDate={dayJs().subtract(10, 'years')}
                            minDate={dayJs().subtract(50, 'years')}
                            views={['year', 'month', 'day']}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={{ xs: 2, sm: 3 }}>
                            <Grid item xs={12} sm={6}>
                              <Text
                                select
                                required
                                label={t('application.field.heightFt.label')}
                                name="heightFt"
                                options={heightFtOptions}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Text
                                select
                                required
                                label={t('application.field.heightIn.label')}
                                name="heightIn"
                                options={heightInOptions}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Text number required length={3} label={t('application.field.weight.label')} name="weight" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl variant="standard" fullWidth>
                            <Text
                              select
                              name="numberOfDeliveries"
                              label={t('application.field.numberOfDeliveries.label')}
                              description={t('application.field.numberOfDeliveries.description')}
                              options={numberOfDeliveriesOptions}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Text
                            select
                            required
                            label={t('application.field.numberOfSections.label')}
                            name="numberOfSections"
                            options={numberOfSectionsOptions}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <BooleanRadio
                            row
                            label={t('application.field.isConvictedOfFelony.label')}
                            required
                            name="isConvictedOfFelony"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <BooleanRadio
                            row
                            label={t('application.field.isUterineMalformation.label')}
                            required
                            name="isUterineMalformation"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <BooleanRadio
                            row
                            label={t('application.field.isSurrogateBefore.label')}
                            required
                            name="isSurrogateBefore"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <EnumSelect
                            required
                            label={t('application.field.referredFrom.label')}
                            translation="application.field.referredFrom.option"
                            name="referredFrom"
                            enum={ReferredFromEnum}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TermsAndMessageConsentCheckboxes
                            termsLabel={t('auth.page.signUp.signUpConfirm')}
                            messageConsentLabel={t('auth.page.signUp.messageConsent')}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      <Button
                        sx={{
                          width: {
                            xs: '100%',
                            sm: 'auto',
                          },
                        }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {t('general.button.submit')}
                      </Button>
                    </Box>
                    <ScrollToError />
                  </Form>
                ),
                [],
              );
            }}
          </Formik>
        )}

        {status === ApplicationStatusEnum.disqualified ? (
          <SignUpInfo
            title={t('auth.page.signUp.block.title')}
            onClick={() => {
              window.location.href = 'https://www.internationalsurrogacycenter.com/contact-us/';
            }}
            buttonText={t('auth.page.signUp.block.button')}
          >
            <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
              {t('auth.page.signUp.block.text1')}
            </Typography>
            <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
              <Trans
                i18nKey={t('auth.page.signUp.block.text2')}
                components={{
                  requirements: (
                    <Link
                      href="https://www.internationalsurrogacycenter.com/requirements-to-become-a-surrogate/"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </SignUpInfo>
        ) : (
          status && (
            <SignUpInfo
              title={t('auth.page.signUp.success.title')}
              onClick={() => navigate('/sign-in')}
              buttonText={t('auth.page.signUp.success.button')}
            >
              <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
                {t('auth.page.signUp.success.text1')}
              </Typography>
              <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
                {t('auth.page.signUp.success.text2')}
              </Typography>
            </SignUpInfo>
          )
        )}
      </Card>
    </>
  );
};
