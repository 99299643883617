import { ChangeEvent, useCallback, useMemo, forwardRef, memo } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { TextField, TextFieldProps, MenuItem, CircularProgress, InputAdornment } from '@mui/material';

import { TextLabel } from './TextLabel';
import { Option } from '../types';

export type TextProps = Omit<TextFieldProps, 'variant'> & {
  readonly options?: Option[];
  readonly isLoading?: boolean;
  readonly number?: boolean;
  readonly numeric?: boolean;
  readonly length?: number;
  readonly readOnly?: boolean;
  readonly description?: string | null;
  readonly capitalize?: boolean;
  readonly withLockIcon?: boolean;
};

export const Text = memo(
  forwardRef<HTMLDivElement, TextProps>(
    (
      {
        options,
        value,
        type,
        label,
        description,
        margin = 'none',
        isLoading = false,
        required,
        number,
        numeric,
        length,
        onChange,
        capitalize = false,
        readOnly,
        select,
        maxRows = 10,
        withLockIcon = false,
        ...props
      },
      ref,
    ) => {
      const isNumeric = numeric || number;

      const SelectProps = useMemo(
        () => ({
          MenuProps: {
            classes: {
              paper: 'styled-scrollbar',
            },
          },
          autoWidth: false,
          displayEmpty: false,
          IconComponent: KeyboardArrowDown,
          ...(props.SelectProps || {}),
        }),
        [props.SelectProps],
      );

      const InputLabelProps = useMemo(
        () => ({
          shrink: false,
          ...(props.InputLabelProps || {}),
        }),
        [props.InputLabelProps],
      );

      const InputProps = useMemo(
        () => ({
          readOnly,
          startAdornment: isLoading ? (
            <InputAdornment sx={{ ml: 1 }} position="start">
              <CircularProgress color="primary" size={25} />
            </InputAdornment>
          ) : undefined,
          ...(props.InputProps || {}),
        }),
        [props.InputProps, isLoading, readOnly],
      );

      const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          if (!length || e.target.value?.length <= length) {
            const currentValue = e.target.value || '';

            if (isNumeric) {
              e.target.value = currentValue.replace(/\D/g, '');
            } else if (!select && capitalize) {
              e.target.value = currentValue.charAt(0).toUpperCase() + currentValue.slice(1);
            }

            if (onChange) {
              onChange(e);
            }
          }
        },
        [onChange, length, isNumeric, capitalize, select],
      );

      const initialValue = useMemo(() => (props.SelectProps?.multiple ? [] : ''), [props.SelectProps?.multiple]);

      return (
        <TextField
          fullWidth
          {...props}
          ref={ref}
          select={select}
          maxRows={maxRows}
          variant="standard"
          margin={margin}
          label={
            label && (
              <TextLabel label={label} required={required} description={description} withLockIcon={withLockIcon} />
            )
          }
          type={number ? 'number' : type}
          value={number && value ? Number(value) : value ?? initialValue}
          InputLabelProps={InputLabelProps}
          SelectProps={SelectProps}
          InputProps={InputProps}
          onChange={handleChange}
        >
          {Array.isArray(options) &&
            options.map(option => (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      );
    },
  ),
);
