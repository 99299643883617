import { useField } from 'formik';
import { useCallback } from 'react';

import { UploadFiles, UploadFilesProps } from '@app/file/components/UploadFiles';
import { File as FileDto } from '@app/file/types';

export interface UploadProps extends Omit<UploadFilesProps, 'onDeleted' | 'onChange' | 'value' | 'maxFiles'> {
  readonly disabled?: boolean;
  readonly withLockIcon?: boolean;
}

export const Upload = ({ name, ...props }: UploadProps) => {
  const fileName = name.includes('.') ? (name.split('.').shift() as string) : name;

  const [{ value }, , { setValue }] = useField(fileName);

  const handleChange = useCallback(async ([avatar]: FileDto[]) => {
    setValue(avatar);
  }, []);

  const handleDelete = useCallback(() => {
    setValue(null);
  }, []);

  return (
    <UploadFiles
      {...props}
      name={name}
      value={value ? [value] : value}
      maxFiles={1}
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
};
