import { Breadcrumbs } from '@app/ui/breadcrumbs/components/Breadcrumbs';
import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@app/auth';
import { camelCase, paramCase } from 'change-case';
import {
  QuestionnaireStatusEnum,
  QuestionnaireSurveyEnum,
  QuestionnaireSurveyFlagEnum,
} from '@app/questionnaire/constants';
import { isVisible } from '@app/dynamic/utils';
import { DynamicFormWizard } from '@app/dynamic';
import defaultImageCard from '../images/card-questionnaire-default.jpg';
import { questionnaireConfig } from '../configs';
import { useUpdateQuestionnaire } from '../hooks/useUpdateQuestionnaire';
import { useQuestionnaireSurveyFlag } from '../hooks/useQuestionnaireSurveyFlag';

export const QuestionnaireForm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { questionnaire: currentQuestionnaire } = useAuth();
  const { survey: surveyParam } = useParams<{ survey: string }>();
  const survey = camelCase(surveyParam as string) as QuestionnaireSurveyEnum;

  const handleFinish = useCallback(() => navigate('/parent-questionnaire'), [navigate]);

  const id = Number(currentQuestionnaire?.id);

  const config = useMemo(() => questionnaireConfig.surveys.find(item => survey === item.name), [survey]);

  const { surveyConfig, initialValues, flags, isGetQuestionnaireLoading, onSubmit, isSurveyVisible } =
    useUpdateQuestionnaire({
      id,
      config,
      withFlags: true,
    });

  const surveyTitle = t(
    `questionnaire.field.survey.option.${survey}.name`,
    (initialValues as {}) || { firstName: '', partner: { firstName: '' } },
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('questionnaire.page.title'),
        to: '/parent-questionnaire',
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle],
  );

  const { toggleFlag } = useQuestionnaireSurveyFlag({ id, survey });

  const handleCompleted = useCallback(() => {
    const surveyConfigs = questionnaireConfig.surveys.filter(surveyItem =>
      isVisible(surveyItem, initialValues as Record<any, any>),
    );

    const nextIndex = surveyConfigs.findIndex(({ name }) => name === survey) + 1;

    const nextSurvey = surveyConfigs[nextIndex];

    if (nextSurvey) {
      navigate(`/parent-questionnaire/${paramCase(nextSurvey.name)}`);
    } else {
      navigate('/parent-questionnaire');
    }
  }, [survey, initialValues, navigate]);

  useEffect(() => {
    if (!isSurveyVisible) {
      handleCompleted();
    }
  }, [isSurveyVisible, handleCompleted]);

  useEffect(() => {
    if (flags && !flags?.[QuestionnaireSurveyFlagEnum.opened]) {
      toggleFlag(QuestionnaireSurveyFlagEnum.opened);
    }
  }, [flags]);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card size="large" img={config?.meta?.cardImage || defaultImageCard}>
        {initialValues && surveyConfig && (
          <DynamicFormWizard
            key={survey}
            opened={flags?.[QuestionnaireSurveyFlagEnum.opened]}
            disabled={initialValues.status !== QuestionnaireStatusEnum.applicants}
            translation="questionnaire.field"
            onSubmit={onSubmit}
            initialValues={initialValues}
            config={surveyConfig}
            onBack={handleFinish}
            onCompleted={handleCompleted}
          />
        )}
      </Card>

      <Box mt={3}>
        <Button color="primary" onClick={handleFinish}>
          {t('questionnaire.form.button.backToAll')}
        </Button>
      </Box>

      <Loader isLoading={isGetQuestionnaireLoading} />
    </Box>
  );
};
