import React, { ElementType } from 'react';
import { Box, ButtonBase, Grid, IconButton, IconButtonProps, SvgIcon, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Label } from '@app/ui/label';
import { ReactComponent as docTimeIcon } from '@app/app/images/icons/icon-doc-time.svg';

interface SurveyItemProps {
  surveyLabel: string;
  progressConfig: {
    populatedPageCount: number;
    pageCount: number;
  };
  statusLabel: string;
  color: IconButtonProps['color'];
  icon: ElementType;
  iconButton: ElementType;
  isAdmin: boolean;
  isSubmitted: boolean;
  isMobile: boolean;
  onClick: () => void;
}

export const SurveyItem: React.FC<SurveyItemProps> = ({
  surveyLabel,
  progressConfig,
  statusLabel,
  color,
  icon,
  iconButton,
  isAdmin,
  isSubmitted,
  isMobile,
  onClick,
}) => {
  return (
    <ButtonBase
      className="list-item"
      onClick={onClick}
      component="div"
      sx={{
        borderTop: theme => `1px solid ${theme.palette.grey[500]}`,
        fontSize: { xs: 14, md: 16 },
        position: 'relative',
        display: 'block',
        pl: {
          xs: 2,
          sm: 3,
          lg: 5,
        },
        pr: {
          xs: 6,
          lg: 5,
        },
        py: {
          xs: 3,
          lg: 4,
        },
        '&:first-of-type': {
          borderTop: 'none',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        },
        '&:hover': {
          boxShadow: {
            md: '0px 5px 25px rgba(0, 0, 0, 0.15)',
          },
        },
      }}
    >
      <Grid container spacing={{ xs: 1, md: 2 }} alignItems="center">
        <Grid item xs={12} sm={7} lg={8}>
          <Typography variant={isMobile ? 'h4' : 'h3'} component="h3">
            {surveyLabel}
          </Typography>
        </Grid>

        <Grid item xs="auto" sm>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: { xs: 11, md: 14 },
              pr: { xs: 1, sm: 2, md: 0 },
              minWidth: 70,
            }}
          >
            <SvgIcon
              component={docTimeIcon}
              viewBox="0 0 24 24"
              sx={{
                color: isSubmitted ? 'text.secondary' : `${color}.main`,
                fontSize: { xs: 18, md: 24 },
                mr: { xs: 0.5, md: 1.5 },
              }}
            />
            {progressConfig.populatedPageCount}/{progressConfig.pageCount}
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sm
          sx={[
            !isAdmin && {
              display: {
                xs: 'none',
                sm: 'block',
              },
            },
          ]}
        >
          <Label
            icon={<SvgIcon component={icon} viewBox="0 0 24 24" fontSize="inherit" />}
            text={statusLabel}
            color={isSubmitted ? 'disabled' : color}
            sx={{
              fontSize: { xs: 11, md: 14 },
              fontWeight: 400,
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            className="filledTonal"
            sx={{
              p: 1,
              opacity: {
                md: isSubmitted ? 1 : 0,
              },
              '.list-item:hover &': {
                opacity: 1,
              },
              position: {
                xs: 'absolute',
                md: 'relative',
              },
              top: {
                xs: '50%',
                md: 'auto',
              },
              right: {
                xs: isAdmin ? 8 : 20,
                sm: 16,
                md: 'auto',
              },
              mt: {
                xs: -2,
                md: 0,
              },
            }}
            color={isAdmin ? 'secondary' : color}
            size="small"
            onClick={onClick}
            disabled={isSubmitted}
          >
            {isAdmin ? <EditOutlinedIcon /> : <SvgIcon component={iconButton} viewBox="0 0 24 24" fontSize="inherit" />}
          </IconButton>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};
