import { ReactNode, useState, memo } from 'react';
import dayJs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IconButton, InputAdornment } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { appConfig } from '@app/app';

import { TextLabel } from './TextLabel';
import { Text } from './Text';

dayJs.extend(utc);

export interface DateProps
  extends Omit<DesktopDatePickerProps<any, any>, 'renderInput' | 'onChange' | 'value' | 'inputFormat'> {
  readonly helperText?: ReactNode;
  readonly error?: boolean;
  readonly placeholder?: string;
  readonly onChange: (value?: string) => void;
  readonly disableManual?: boolean;
  readonly value?: string;
  readonly format?: string;
  readonly readOnly?: boolean;
  readonly required?: boolean;
  readonly description?: string;
  readonly name?: string;
  readonly withLockIcon?: boolean;
}

export const Date = memo(
  ({
    value,
    helperText,
    error,
    placeholder,
    onChange,
    format = appConfig.format.date,
    readOnly = false,
    disableManual = false,
    disabled,
    required,
    description,
    label,
    name,
    withLockIcon = false,
    ...props
  }: DateProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <DesktopDatePicker
        open={isOpen}
        readOnly={readOnly}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={
          label && <TextLabel label={label} required={required} description={description} withLockIcon={withLockIcon} />
        }
        {...props}
        slots={{
          textField: Text,
        }}
        slotProps={{
          popper: {
            placement: 'bottom-end',
            ...props.slotProps?.popper,
          },
          textField: {
            fullWidth: true,
            variant: 'outlined',
            name,
            helperText,
            error,
            inputProps: { placeholder, readOnly: disableManual || readOnly },
            InputProps: {
              sx: {
                position: 'relative',
                pr: 0,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                  }}
                >
                  {!readOnly && (
                    <IconButton color="inherit" onClick={() => setIsOpen(true)} disabled={disabled}>
                      <CalendarTodayIcon fontSize="medium" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          },
        }}
        value={value ? dayJs(value, appConfig.format.isoDateTime) : value || null}
        format={format}
        onChange={(date: Dayjs | null) => {
          onChange(date ? date.format(appConfig.format.isoDateTime) : '');
        }}
        disabled={disabled}
      />
    );
  },
);
