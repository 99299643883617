import { FC, memo, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Option } from '@app/ui/forms/types';
import {
  Autocomplete,
  BooleanRadio,
  ComputedText,
  Date,
  EnumCheckboxGroup,
  EnumRadioGroup,
  EnumSelect,
  FormattedText,
  Phone,
  Text,
  ToggleButton,
  Upload,
  MoneyText,
  Checkbox,
} from '@app/formik';

import { DynamicFormFieldInformation } from './field/DynamicFormFieldInformation';
import { DynamicFormFieldToggleButtonGroup } from './field/DynamicFormFieldToggleButtonGroup';
import { DynamicFormComponentProps, DynamicFormConfigElement } from '../types';
import { DynamicFormElementType } from '../constants';

const DYNAMIC_FORM_COMPONENT_MAP = {
  [DynamicFormElementType.text]: Text,
  [DynamicFormElementType.enumSelect]: EnumSelect,
  [DynamicFormElementType.enumRadio]: EnumRadioGroup,
  [DynamicFormElementType.enumCheckbox]: EnumCheckboxGroup,
  [DynamicFormElementType.phone]: Phone,
  [DynamicFormElementType.date]: Date,
  [DynamicFormElementType.booleanRadio]: BooleanRadio,
  [DynamicFormElementType.toggleButton]: ToggleButton,
  [DynamicFormElementType.autocomplete]: Autocomplete,
  [DynamicFormElementType.information]: DynamicFormFieldInformation,
  [DynamicFormElementType.toggleButtonGroup]: DynamicFormFieldToggleButtonGroup,
  [DynamicFormElementType.upload]: Upload,
  [DynamicFormElementType.computedText]: ComputedText,
  [DynamicFormElementType.formattedText]: FormattedText,
  [DynamicFormElementType.moneyText]: MoneyText,
  [DynamicFormElementType.checkbox]: Checkbox,
};

export interface DynamicElementProps extends DynamicFormComponentProps<DynamicFormConfigElement> {
  readonly values: Record<any, any>;
}

export const DynamicFormElement = memo(({ translation, config, values, readOnly, disabled }: DynamicElementProps) => {
  const { t } = useTranslation('common');

  const staticProps = useMemo(() => {
    const commonProps: Record<any, any> = {
      name: config.name,
    };

    const alias = config?.alias || config.name;

    const currentTranslation = config?.translation ?? translation;

    if (!config.noLabel) {
      commonProps.label = <Trans>{t(`${currentTranslation}.${alias}.label`)}</Trans>;
    }

    if (config.showDescription) {
      commonProps.description = t(`${currentTranslation}.${alias}.description`);
    }

    if (config.type !== DynamicFormElementType.toggleButtonGroup && config.props?.withLockIcon) {
      commonProps.description = t('questionnaire.tooltip.notShared.description');
    }

    if (config.type === DynamicFormElementType.information && currentTranslation.includes('.field')) {
      const [formattedTranslation] = currentTranslation.split('.field');
      commonProps.translation = formattedTranslation;
    }

    if (
      [
        DynamicFormElementType.enumRadio,
        DynamicFormElementType.enumCheckbox,
        DynamicFormElementType.enumSelect,
        DynamicFormElementType.booleanRadio,
        DynamicFormElementType.toggleButton,
      ].includes(config.type)
    ) {
      commonProps.translation = config?.aliasOption
        ? `${currentTranslation}.${config.aliasOption}.option`
        : `${currentTranslation}.${alias}.option`;
    }

    if (config.type === DynamicFormElementType.text) {
      commonProps.capitalize = true;
    }

    if (config.type === DynamicFormElementType.date) {
      commonProps.disableManual = true;
    }

    if (config.props) {
      return { ...commonProps, ...config.props };
    }

    return commonProps;
  }, [config, translation, t]);

  const dynamicProps = useMemo(() => {
    const commonProps: Record<any, any> = {};

    if (config.optionVisibleIf) {
      // @ts-ignore
      commonProps.optionVisibleIf = (option: Option) => config.optionVisibleIf(option, values);
    }

    return commonProps;
  }, [values, config]);

  const Component = DYNAMIC_FORM_COMPONENT_MAP[config.type] as FC<Record<any, any>>;

  return <Component disabled={disabled} {...staticProps} {...dynamicProps} readOnly={readOnly} />;
});
