import { UpdatePdfCoverModalProps } from '@app/application/components/pdf/UpdatePdfCoverModal';
import { COUNTRIES } from '@app/application/constants/countries';
import { DynamicFormConfigSurvey } from '@app/dynamic';
import { Autocomplete, ScrollToError, yup, Text, EnumSelect } from '@app/formik';
import { EmbryosStatusEnum } from '@app/questionnaire/constants';
import { useUpdateQuestionnaire } from '@app/questionnaire/hooks/useUpdateQuestionnaire';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { success } from '@app/snackbars';
import { Loader } from '@app/ui/loader';
import { Modal } from '@app/ui/modal';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  country: yup.string().nullable(),
  city: yup.string().nullable(),
  firstParentAge: yup.number().nullable(),
  secondParentAge: yup.number().nullable(),
  embryoStatus: yup.string().nullable(),
  numberOfEmbryos: yup.number().nullable(),
  clinicName: yup.string().nullable(),
  clinicLocation: yup.string().nullable(),
  about: yup.string().nullable(),
  additionalNotes: yup.string().nullable(),
});

const config: DynamicFormConfigSurvey = {
  name: 'coverPdfPage',
  meta: {
    dependOnFields: [
      'highlights',
      'isPartnerInformation',
      'age',
      'partner.firstName',
      'partner.age',
      'embryosStatus',
      'numberOfEmbryos',
      'clinicName',
      'clinicLocation',
    ] as (keyof Questionnaire)[],
  },
  pages: [],
};

export const QuestionnaireUpdatePdfCoverModal: React.FC<UpdatePdfCoverModalProps> = ({ id, open, onClose }) => {
  const { t } = useTranslation('common');

  const { initialValues, isGetQuestionnaireLoading, isUpdateQuestionnaireLoading, onSubmit } = useUpdateQuestionnaire({
    id,
    isAdmin: true,
    config,
    onUpdateSuccess: () => {
      onClose();
      success(t('application.pdf.modal.successMessage'));
    },
  });

  const prefilledValues = useMemo(
    () => ({
      country: initialValues?.highlights?.country || '',
      city: initialValues?.highlights?.city || '',
      firstParentAge: initialValues?.highlights?.firstParentAge || initialValues?.age || 0,
      secondParentAge: initialValues?.highlights?.secondParentAge || initialValues?.partner?.age || 0,
      embryoStatus: initialValues?.highlights?.embryoStatus || initialValues?.embryosStatus,
      numberOfEmbryos: initialValues?.highlights?.numberOfEmbryos || initialValues?.numberOfEmbryos || 0,
      clinicName: initialValues?.highlights?.clinicName || initialValues?.clinicName || '',
      clinicLocation: initialValues?.highlights?.clinicLocation || initialValues?.clinicLocation || '',
      about: initialValues?.highlights?.about || '',
      additionalNotes: initialValues?.highlights?.additionalNotes || '',
    }),
    [initialValues],
  );

  const submit = async (values: Questionnaire['highlights'], helpers: any) => {
    await onSubmit(
      {
        id,
        partner: initialValues?.partner,
        isPartnerInformation: initialValues?.isPartnerInformation,
        highlights: values,
      },
      helpers,
    );
  };

  return (
    <Modal open={open} onClose={onClose} title={t('questionnaire.pdf.modal.title')}>
      <Loader isLoading={isGetQuestionnaireLoading || isUpdateQuestionnaireLoading} />

      <Typography paragraph color="textSecondary">
        {t('questionnaire.pdf.modal.subtitle')}
      </Typography>

      {initialValues && (
        <Formik
          enableReinitialize
          onSubmit={submit}
          initialValues={prefilledValues}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4">{t('questionnaire.pdf.modal.locationDetails')}</Typography>
                </Grid>
                <>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete label={t('questionnaire.field.country.label')} name="country" options={COUNTRIES} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text label={t('questionnaire.field.city.label')} name="city" length={60} />
                  </Grid>
                </>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4">{t('questionnaire.pdf.modal.ageInformation')}</Typography>
                </Grid>
                <>
                  <Grid item xs={12} sm={6}>
                    <Text
                      label={t('questionnaire.field.parentAge.label', { name: initialValues.firstName })}
                      name="firstParentAge"
                      number
                    />
                  </Grid>
                  {initialValues.partner && initialValues.isPartnerInformation && (
                    <Grid item xs={12} sm={6}>
                      <Text
                        label={t('questionnaire.field.parentAge.label', { name: initialValues.partner?.firstName })}
                        name="secondParentAge"
                        number
                      />
                    </Grid>
                  )}
                </>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4">{t('questionnaire.pdf.modal.embryoStatus')}</Typography>
                </Grid>
                <>
                  <Grid item xs={12}>
                    <EnumSelect
                      label={t('questionnaire.field.embryosStatus.label')}
                      name="embryoStatus"
                      enum={EmbryosStatusEnum}
                      translation="questionnaire.field.embryosStatus.option"
                    />
                  </Grid>
                  {values.embryoStatus === EmbryosStatusEnum.ready && (
                    <Grid item xs={12} sm={6}>
                      <Text label={t('questionnaire.field.numberOfEmbryos.label')} name="numberOfEmbryos" number />
                    </Grid>
                  )}
                </>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4">{t('questionnaire.pdf.modal.clinicDetails')}</Typography>
                </Grid>
                <>
                  <Grid item xs={12}>
                    <Text label={t('questionnaire.field.clinicName.label')} name="clinicName" length={100} />
                  </Grid>
                  <Grid item xs={12}>
                    <Text label={t('questionnaire.field.clinicLocation.label')} name="clinicLocation" length={100} />
                  </Grid>
                </>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4">{t('questionnaire.pdf.modal.additionalInformation')}</Typography>
                </Grid>
                <>
                  <Grid item xs={12}>
                    <Text label={t('questionnaire.field.about.label')} name="about" multiline length={750} />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      label={t('questionnaire.field.additionalNotes.label')}
                      name="additionalNotes"
                      multiline
                      length={750}
                    />
                  </Grid>
                </>
              </Grid>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 3 }}>
                <Button color="primary" onClick={onClose}>
                  {t('general.button.cancel')}
                </Button>

                <LoadingButton loading={isUpdateQuestionnaireLoading} color="primary" variant="contained" type="submit">
                  {t('general.button.save')}
                </LoadingButton>
              </Box>

              <ScrollToError />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
