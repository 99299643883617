import { SignUpParentConfirm } from '@app/auth/components/SIgnUpParentConfirm';
import { LightLayout } from '@app/layouts';

export const SignUpParentConfirmPage = () => {
  return (
    <LightLayout>
      <SignUpParentConfirm />;
    </LightLayout>
  );
};
