import { gql } from '@apollo/client';

export const GET_AUTH_USER = gql`
  {
    authWhoAmI {
      id
      name
      role
      email
      applications {
        id
        phone
        firstName
        lastName
      }
      questionnaires {
        id
        phone
        firstName
        lastName
      }
    }
  }
`;

export const AUTH_SIGN_UP = gql`
  mutation authSignUp($input: AuthSignUpInput!) {
    authSignUp(input: $input) {
      id
      applications {
        status
      }
    }
  }
`;

export const AUTH_SIGN_UP_PARENT = gql`
  mutation authSignUp($input: AuthSignUpParentInput!) {
    authSignUpParent(input: $input) {
      id
    }
  }
`;

export const AUTH_CHANGE_EMAIL = gql`
  mutation authChangeEmail($input: AuthChangeEmailInput!) {
    authChangeEmail(input: $input) {
      id
    }
  }
`;

export const AUTH_CHANGE_EMAIL_CONFIRM = gql`
  mutation authChangeEmail($input: AuthChangeEmailConfirmInput!) {
    authChangeEmailConfirm(input: $input) {
      id
      email
    }
  }
`;
