import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Card } from '@app/ui/card';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import cardImg from '../images/card-questionnaire-upload-photos.jpg';

export interface UserFinishQuestionnaireProps {
  readonly onBack?: () => void;
}

export const UserFinishQuestionnaire = ({ onBack }: UserFinishQuestionnaireProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const pageTitle = t('questionnaire.finish.thankYou.title');

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('questionnaire.page.title'),
        to: '/parent-questionnaire',
      },
      {
        title: pageTitle,
      },
    ],
    [t],
  );

  const handleUpload = useCallback(() => navigate('/parent-questionnaire/upload-photos'), []);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />

      <Card size="large" img={cardImg}>
        <Typography variant="h1" gutterBottom>
          {pageTitle}
        </Typography>
        <Typography paragraph>{t('questionnaire.finish.thankYou.description.item1')}</Typography>
        <Typography>{t('questionnaire.finish.thankYou.description.item2')}</Typography>
        <Box sx={{ display: { sm: 'flex' }, justifyContent: 'flex-end', mt: 3 }}>
          <Button sx={{ width: { xs: '100%', sm: 'auto' } }} variant="contained" color="primary" onClick={handleUpload}>
            {t('questionnaire.button.uploadNow.name')}
          </Button>
          <Button
            className="filledTonal"
            sx={{ width: { xs: '100%', sm: 'auto' }, ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
            variant="contained"
            color="primary"
            onClick={onBack}
          >
            {t('questionnaire.button.uploadLater.name')}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};
