import { useCallback, useMemo } from 'react';
import { camelCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { DynamicForm } from '@app/dynamic';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Breadcrumbs } from '@app/ui/breadcrumbs';

import { getName } from '../utils';
import defaultImageCard from '../images/card-application-personal-info.jpg';
import { useUpdateApplication } from '../hooks/useUpdateApplication';
import { applicationConfig } from '../configs';

export const AdminApplicationForm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    id,
    survey: surveyParam,
    entity,
  } = useParams<{ id?: string; survey: string; entity: 'surrogates' | 'parent' }>();
  const survey = camelCase(surveyParam as string);

  const handleBack = useCallback(() => navigate(`/admin/${entity}/${id}`), [id, entity]);

  const config = useMemo(() => applicationConfig.surveys.find(item => survey === item.name), [survey]);

  const { surveyConfig, initialValues, isUpdateApplicationLoading, isGetApplicationLoading, onSubmit } =
    useUpdateApplication({
      config,
      id: Number(id),
      isAdmin: true,
      onUpdateSuccess: handleBack,
    });

  const surveyTitle = t(
    `application.field.survey.option.${survey}.name`,
    initialValues || { firstName: '', partner: { firstName: '' } },
  );

  const nameTitle = useMemo(() => {
    if (initialValues) {
      return getName(initialValues);
    }
    return '';
  }, [initialValues]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t(`user.page.adminList.title`),
        to: `/admin/${entity}`,
      },
      {
        title: nameTitle,
        to: `/admin/${entity}/${id}`,
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle, id, entity],
  );

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card size="large" img={config?.meta?.cardImage || defaultImageCard}>
        {initialValues && surveyConfig && (
          <DynamicForm
            translation="application.field"
            onSubmit={onSubmit}
            onBack={handleBack}
            initialValues={initialValues}
            config={surveyConfig}
          />
        )}
      </Card>

      <Loader isLoading={isUpdateApplicationLoading || isGetApplicationLoading} />
    </Box>
  );
};
