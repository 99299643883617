import { createTheme, SvgIcon } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import { alpha } from '@mui/material/styles';

//use module augmentation to extend the default theme structure
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { ReactComponent as checkboxIcon } from '@app/app/images/icons/icon-checkbox.svg';
import { ReactComponent as checkboxCheckedIcon } from '@app/app/images/icons/icon-checkbox-checked.svg';
import { ReactComponent as radioIcon } from '@app/app/images/icons/icon-radio.svg';
import { ReactComponent as radioCheckedIcon } from '@app/app/images/icons/icon-radio-checked.svg';

const fontFamily = '"Open Sans", sans-serif';
const inputVerticalPadding = 8;
const inputHorizontalPadding = 12;

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1320,
    xl: 1920,
  },
});

export const palette = {
  primary: {
    light: '#F7EBF4',
    main: '#B03891',
    dark: '#97307C',
    contrastText: '#FFFFFF',
  },
  primaryLight: {
    light: '#FAF4F9',
    main: '#F7EBF4',
    dark: '#EDDEE9',
    contrastText: '#B03891',
  },
  secondary: {
    light: '#EAF0F9',
    main: '#2A6BBF',
  },
  error: {
    light: '#FDEEEE',
    main: '#EB5757',
  },
  success: {
    light: '#E0F7E9',
    main: '#27AE60',
  },
  info: {
    light: '#E0F2FF',
    main: '#0A84DD',
  },
  warning: {
    main: '#EAB600',
    light: '#FFF8E1',
  },
  text: {
    primary: '#333333',
    secondary: '#7E8288',
    disabled: '#A4ADBA',
  },
  grey: {
    100: '#F1F1F1',
    200: '#F8F8F8',
    400: '#EFF2F4',
    500: '#D6D8DF',
    600: '#C3C9D2',
    700: '#7E8288',
  },
};

export const theme = createTheme({
  breakpoints,
  palette,
  typography: {
    fontFamily,
    fontSize: 14,
    fontWeightBold: 600,

    h1: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.4,

      [breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,

      [breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,

      [breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.4,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.4,
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          WebkitTextSizeAdjust: '100%',
        },
        body: {
          backgroundColor: palette.grey[200],
          '&, & > #root': {
            height: '100%',
          },
          '*::selection': {
            background: palette.primary.light,
          },
          '& *': {
            scrollbarColor: `${palette.grey[600]} ${palette.grey[400]}` /* thumb and track color for Firefox */,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              backgroundColor: palette.grey[400],
              borderRadius: 50,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: palette.grey[400],
              borderRadius: 50,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: palette.grey[600],
              borderRadius: 50,
            },
          },
          '& .box-sticky': {
            position: 'sticky',
            bottom: 0,
            zIndex: 100,
          },
          '.list': {
            paddingLeft: 0,
            listStylePosition: 'inside',
            '& > li:not(:last-of-type)': {
              marginBottom: 14,
            },
          },
          // Hide recaptcha badge
          '.grecaptcha-badge': {
            visibility: 'hidden',
          },
        },
        u: {
          textDecoration: 'underline',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.7em',
        },
        paragraph: {
          marginBottom: '20px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid ${palette.grey[500]}`,
        },
        colorDefault: {
          backgroundColor: '#FFF',
          color: palette.text.primary,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 60,

          '@media (min-width: 0px) and (orientation: landscape)': {
            minHeight: 60,
          },
          [breakpoints.up('sm')]: {
            minHeight: 60,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          boxShadow: 'none !important',
        },
        contained: {
          padding: '8px 20px',

          '&.Mui-disabled, &.Mui-disabled:hover': {
            backgroundColor: palette.grey[100],
            color: palette.text.disabled,
          },
          '&.filledTonal:not(.Mui-disabled)': {
            '&.MuiButton-containedPrimary': {
              backgroundColor: palette.primary.light,
              color: palette.primary.main,
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: palette.secondary.light,
              color: palette.secondary.main,
            },
            '&.MuiButton-containedSuccess': {
              backgroundColor: palette.success.light,
              color: palette.success.main,
            },
            '&.MuiButton-containedWarning': {
              backgroundColor: palette.warning.light,
              color: palette.warning.main,
            },
          },
        },
        outlined: {
          padding: '7px 20px',

          '&.Mui-disabled, &.Mui-disabled:hover': {
            borderColor: palette.grey[100],
            color: palette.text.disabled,
          },
        },
        text: {
          padding: '0 1px',
          minWidth: 0,

          '&:hover': {
            background: 'none !important',
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            color: palette.text.disabled,
          },
          '& .MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: 4,
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.filledTonal:not(.Mui-disabled)': {
            '&.MuiIconButton-colorPrimary': {
              backgroundColor: palette.primary.light,
            },
            '&.MuiIconButton-colorSecondary': {
              backgroundColor: palette.secondary.light,
            },
            '&.MuiIconButton-colorSuccess': {
              backgroundColor: palette.success.light,
            },
            '&.MuiIconButton-colorWarning': {
              backgroundColor: palette.warning.light,
            },
          },
          '&.Mui-disabled, &.Mui-disabled:hover': {
            backgroundColor: palette.grey[100],
            color: palette.text.disabled,
          },
        },
        sizeSmall: {
          padding: 4,
          fontSize: 18,

          '& svg': {
            fontSize: 'inherit',
          },
        },
        edgeEnd: {
          marginRight: '-6px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          borderColor: palette.grey[500],
          fontWeight: 400,

          '&.Mui-disabled': {
            color: palette.text.disabled,
            borderColor: `${palette.grey[100]} !important`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: palette.primary.dark,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 0,
          marginBottom: 18,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 1.3,
          textAlign: 'left',
          marginBottom: 6,
          cursor: 'default',
          display: 'block',
          maxWidth: '100%',

          '&, &.Mui-focused:not(.Mui-error)': {
            color: palette.text.secondary,
          },
          '&.Mui-disabled': {
            '&, & .MuiInputLabel-asterisk': {
              color: palette.text.disabled,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
      },
      styleOverrides: {
        root: {
          position: 'static',
          transform: 'none',
          whiteSpace: 'normal',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 1.4,
          borderRadius: 8,

          '&.Mui-focused, &:focus': {
            outline: 0,
          },
          '&.Mui-disabled': {
            backgroundColor: `${palette.grey[200]} !important`,

            '&, & .MuiInput-input': {
              color: palette.text.primary,
              WebkitTextFillColor: palette.text.primary, // fix IOS color lighter
            },
          },
        },
        input: {
          height: 'auto',

          'label[data-shrink=false] + .MuiInputBase-formControl &::-webkit-input-placeholder': {
            // always show placeholder
            opacity: '1 !important',
          },
          'label[data-shrink=false] + .MuiInputBase-formControl &::-moz-placeholder': {
            // always show placeholder
            opacity: '1 !important',
          },
          '&::-webkit-input-placeholder': {
            color: palette.text.disabled,
            opacity: 1,
            visibility: 'visible',
          },
          '&::-moz-placeholder': {
            color: palette.text.disabled,
            opacity: 1,
            visibility: 'visible',
          },
        },
        adornedStart: {
          paddingLeft: inputHorizontalPadding / 2,
        },
        inputAdornedStart: {
          '&.MuiInput-input': {
            paddingLeft: inputHorizontalPadding / 2,
          },
        },
        adornedEnd: {
          paddingRight: inputHorizontalPadding / 2,
        },
        inputAdornedEnd: {
          '&.MuiInput-input': {
            paddingRight: inputHorizontalPadding / 2,
          },
        },
        multiline: {
          padding: 0,

          '& textarea[rows="3"]': {
            minHeight: 60,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.grey[500]}`,
          backgroundColor: '#FFF',

          '&.Mui-focused, &:focus': {
            borderColor: palette.primary.main,
          },
          '&.Mui-error': {
            borderColor: palette.error.main,
          },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        underline: {
          '&:before, &:after': {
            content: 'none',
          },
          '&.Mui-focused': {
            borderWidth: 1,
          },
        },
        input: {
          padding: `${inputVerticalPadding}px ${inputHorizontalPadding}px`,
        },
        // inputMultiline: {
        //   margin: 2,
        // },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInput-root .MuiInput-input': {
            padding: `${inputVerticalPadding}px ${inputHorizontalPadding}px`,
          },
        },
        paper: {
          margin: '4px 0',
          padding: '6px 0',
          overflowX: 'hidden',
          overflowY: 'auto',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        },
        listbox: {
          padding: 0,
          maxHeight: '200px !important',

          '& .MuiAutocomplete-option': {
            backgroundColor: '#FFF',
            fontSize: 14,
            display: 'block',
            color: palette.text.primary,
            padding: '6px 12px',
            minHeight: 'auto',
            textOverflow: 'ellipsis',

            '&, &.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focused, &:hover': {
              backgroundColor: palette.grey[100],
            },
            '&[aria-selected="true"]': {
              color: palette.text.primary,
              fontWeight: 600,

              '&, &:hover, &:focus': {
                backgroundColor: palette.primary.light,
              },
            },
            '& .MuiTouchRipple-child': {
              color: palette.primary.main,
            },
          },
        },
        endAdornment: {
          top: 'calc(50% - 12px)',
          transform: 'none',
        },
        popupIndicator: {
          backgroundColor: 'transparent !important',
          color: palette.grey[700],
          fontSize: 18,
          marginRight: 5,

          '& .MuiSvgIcon-root': {
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
          },
        },
        icon: {
          fontSize: 18,
          color: palette.grey[700],
          marginRight: 5,
        },
        multiple: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          margin: '4px 0',
          padding: '6px 0',
          overflowX: 'hidden',
          overflowY: 'auto',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        },
        list: {
          padding: 0,
          maxHeight: '200px !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          fontSize: 14,
          color: palette.text.primary,
          padding: '6px 12px',
          minHeight: 'auto',
          whiteSpace: 'normal',

          '&, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
          '&.Mui-focused, &:hover': {
            backgroundColor: palette.grey[100],
          },
          '&.Mui-selected': {
            color: palette.text.primary,
            fontWeight: 600,

            '&, &:hover, &:focus': {
              backgroundColor: palette.primary.light,
            },
          },
          '& .MuiTouchRipple-child': {
            color: palette.primary.main,
          },
          '&:not(:last-of-type)': {
            marginBottom: 2,
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& button': {
            padding: 6,
            fontSize: 18,
          },
          '& svg': {
            color: 'inherit',
            fontSize: 'inherit',

            '&.MuiSvgIcon-fontSizeMedium': {
              fontSize: 16,
            },
          },
        },
        positionStart: {
          color: palette.primary.main,
        },
        positionEnd: {
          color: palette.text.secondary,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 11,
          marginTop: '0.4em',
          lineHeight: 1.2,
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -8,
          alignItems: 'flex-start',

          '& .MuiFormControlLabel-label, & .MuiStack-root': {
            marginTop: 10,
            marginLeft: 2,
          },
        },
        labelPlacementTop: {
          alignItems: 'flex-start',
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        icon: <SvgIcon component={checkboxIcon} viewBox="0 0 18 18" />,
        checkedIcon: <SvgIcon component={checkboxCheckedIcon} viewBox="0 0 18 18" />,
      },
      styleOverrides: {
        root: {
          padding: 6,
          color: palette.grey[600],
          fontSize: 20,

          '&.Mui-disabled': {
            color: `${palette.grey[500]} !important`,

            '& .bg-control': {
              fill: `${palette.grey[400]} !important`,
            },
          },
          '& svg': {
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        icon: <SvgIcon component={radioIcon} viewBox="0 0 18 18" />,
        checkedIcon: <SvgIcon component={radioCheckedIcon} viewBox="0 0 18 18" />,
      },
      styleOverrides: {
        root: {
          padding: 8,
          color: palette.grey[600],
          fontSize: 24,

          '&.Mui-disabled': {
            color: `${palette.grey[500]} !important`,

            '& .bg-control': {
              fill: `${palette.grey[400]} !important`,
            },
          },
          '& svg': {
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#000', 0.3),
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 20,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          width: '100%',
          border: `1px solid ${palette.grey[500]}`,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          position: 'relative',
          zIndex: 1,
          marginBottom: -20,

          [breakpoints.up('sm')]: {
            marginBottom: -40,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flex: 1,
          backgroundColor: '#FFF',
          borderRadius: 20,
          position: 'relative',
          zIndex: 2,
          border: `1px solid ${palette.grey[500]}`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: `${palette.text.primary} !important`,
          fontSize: 14,
          lineHeight: 1.4,
          fontWeight: 400,
          padding: 14,
          display: 'flex',
          alignItems: 'center',
          boxShadow: '0px 4px 10px rgba(138, 138, 135, 0.25)',
          borderRadius: 8,

          [breakpoints.up('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
          },
        },
        icon: {
          fontSize: 24,
          padding: 0,
          marginRight: 10,

          [breakpoints.up('sm')]: {
            marginRight: 15,
          },
        },
        message: {
          padding: 0,
        },
        outlinedError: {
          borderColor: palette.error.main,
          backgroundColor: palette.error.light,
        },
        outlinedSuccess: {
          borderColor: palette.success.main,
          backgroundColor: palette.success.light,
        },
        outlinedWarning: {
          borderColor: palette.warning.main,
          backgroundColor: palette.warning.light,

          '& .MuiAlert-icon': {
            color: palette.warning.main,
          },
        },
        outlinedInfo: {
          borderColor: palette.primary.main,
          backgroundColor: palette.primary.light,

          '& .MuiAlert-icon': {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFF',
          color: palette.text.primary,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          padding: '12px 18px',
          maxWidth: 320,
          border: `1px solid ${palette.grey[500]}`,
          borderRadius: 8,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        },
        touch: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          padding: '12px 18px',
        },
        tooltipPlacementTop: {
          margin: '10px 0 !important',
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          background: '#FFF',
          border: `1px solid ${palette.grey[500]}`,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          borderRadius: 8,
          margin: '5px 0',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        today: {
          border: `1px solid ${palette.primary.main} !important`,
        },
      },
    },

    //Data Grid (Tables)
    MuiDataGrid: {
      defaultProps: {
        disableColumnSelector: true,
        disableRowSelectionOnClick: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          fontSize: 14,
          borderRadius: 8,
          borderColor: palette.grey[500],

          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            flex: 1,
            padding: '5px 10px',

            [breakpoints.up('sm')]: {
              paddingLeft: 20,
              paddingRight: 20,
            },

            '&:focus, &:focus-within': {
              outline: '0 !important',
            },
            '&:first-of-type': {
              paddingLeft: 16,

              [breakpoints.up('sm')]: {
                paddingLeft: 36,
              },
            },
            '&:last-of-type': {
              paddingLeft: 16,

              [breakpoints.up('sm')]: {
                paddingLeft: 36,
              },
            },
          },
        },
        columnHeaders: {
          backgroundColor: palette.grey[100],
          borderColor: palette.grey[500],
          borderRadius: '8px 8px 0 0',
          '.MuiDataGrid-row--borderBottom': {
            background: 'inherit !important',
          },
        },
        row: {
          '&:hover, &.Mui-hovered': {
            backgroundColor: 'rgba(0,0,0,0.015)',
          },
        },
        cell: {
          borderColor: palette.grey[500],
        },
        columnHeaderTitle: {
          fontWeight: 600,
          color: palette.text.secondary,
        },
        columnSeparator: {
          display: 'none !important',
        },
        iconButtonContainer: {
          visibility: 'visible',
          width: 'auto',
          marginLeft: 3,

          '.MuiDataGrid-sortIcon': {
            opacity: '1 !important',
            fill: 'currentColor',
          },
          '.MuiIconButton-root': {
            width: 24,
            height: 24,

            '.MuiDataGrid-columnHeader--sort &': {
              color: palette.grey[700],
            },
            '.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted &': {
              color: palette.primary.main,
            },
          },
        },
        footerContainer: {
          backgroundColor: palette.grey[100],
          borderColor: palette.grey[500],
          borderRadius: '0 0 8px 8px',
          paddingLeft: 16,
          paddingRight: 16,

          [breakpoints.up('sm')]: {
            paddingLeft: 36,
            paddingRight: 36,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          fontSize: 12,
        },
        toolbar: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          minHeight: 0,
          paddingLeft: 10,
          paddingRight: 0,
        },
        selectRoot: {
          minWidth: 55,
          marginLeft: 10,
          marginRight: 16,

          [breakpoints.up('sm')]: {
            marginLeft: 15,
            marginRight: 30,
          },
        },
        select: {
          border: `1px solid ${palette.grey[500]}`,
          paddingTop: 6,
          paddingBottom: 6,
          color: palette.text.secondary,

          '&, &:focus': {
            backgroundColor: '#FFF !important',
            borderRadius: 8,
          },
        },
        selectIcon: {
          color: palette.text.secondary,
          fontSize: 18,
        },
        actions: {
          color: palette.text.secondary,
          marginLeft: 10,

          [breakpoints.up('sm')]: {
            marginLeft: 20,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        firstLast: {
          color: palette.text.secondary,
          margin: 0,
        },
        previousNext: {
          color: palette.text.secondary,
          margin: 0,
        },
        page: {
          backgroundColor: '#FFF',
          border: `1px solid ${palette.grey[500]}`,

          '&.Mui-selected': {
            backgroundColor: palette.grey[500],
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderColor: palette.grey[500],
          borderRadius: 8,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',

          [breakpoints.down('sm')]: {
            margin: 15,
            width: 'calc(100% - 30px)',

            '&.MuiDialog-paperScrollBody': {
              maxWidth: 'calc(100% - 30px)',
            },
            '&.MuiDialog-paperScrollBody.MuiDialog-paperFullScreen': {
              width: '100%',
              maxWidth: '100%',
            },
          },
        },
        paperWidthSm: {
          [breakpoints.up('sm')]: {
            maxWidth: 400,
          },
        },
        paperWidthMd: {
          [breakpoints.up('md')]: {
            maxWidth: 600,
          },
        },
        paperWidthLg: {
          [breakpoints.up('lg')]: {
            maxWidth: 800,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,

          [breakpoints.up('sm')]: {
            padding: 30,
          },

          '.MuiDialogTitle-root + &': {
            paddingTop: '12px',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px 0',

          [breakpoints.up('sm')]: {
            padding: '30px 30px 0',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 24px 16px',
          marginTop: '-8px',

          [breakpoints.up('sm')]: {
            padding: '0 30px 30px',
            marginTop: '-16px',
            justifyContent: 'space-between',
          },
          '& > :not(:first-of-type)': {
            marginLeft: 8,
          },

          [breakpoints.down('sm')]: {
            flexDirection: 'column',

            '& > *': {
              width: '100% !important',

              '&:not(:first-of-type)': {
                marginLeft: 0,
                marginTop: 8,
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
        outlined: {
          border: `1px solid ${palette.grey[500]}`,
        },
        elevation1: {
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[100],
          height: 8,
          borderRadius: 10,
        },
        bar: {
          borderRadius: 10,
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    primaryLight?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
  }
}
