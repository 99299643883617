import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useFormikContext } from 'formik';
import { ReactComponent as lockIcon } from '@app/app/images/icons/icon-lock.svg';

export interface DynamicFormFieldInformationProps {
  readonly name: string;
  readonly template: any;
  readonly translation?: string;
  readonly variables?: Record<any, any>;
  readonly pageInner?: boolean;
  readonly withLockIcon?: boolean;
}

export const DynamicFormFieldInformation = memo(
  ({ name, template, variables, pageInner, translation }: DynamicFormFieldInformationProps) => {
    const { t } = useTranslation('common');

    const { values } = useFormikContext<Record<any, any>>();

    const fullValues = { ...variables, ...values };

    return (
      <>
        {template.map((item: any) => {
          return (
            <Box
              key={item}
              sx={{
                fontSize: pageInner ? 12 : 14,
                color: pageInner ? 'text.secondary' : 'text.primary',
                '&:not(:last-of-type)': { mb: 3 },
              }}
            >
              {Array.isArray(item) &&
                item.map((listItem: string) => {
                  const listItemText = t(`${translation}.field.${name}.${listItem}`, fullValues);
                  const isItemWithLockIcon = listItemText.includes('{{lockIcon}}');

                  if (isItemWithLockIcon) {
                    const [firstPart, lastPart] = listItemText.split('{{lockIcon}}');

                    return (
                      <Box key={listItem} display="flex" alignItems="start" mb={2}>
                        <CheckIcon color="primary" sx={{ mr: 2, fontSize: 20 }} />
                        <Typography fontSize="inherit">
                          {firstPart} <SvgIcon component={lockIcon} sx={{ mb: -1 }} />
                          {lastPart}
                        </Typography>
                      </Box>
                    );
                  }
                  return (
                    <Box key={listItem} display="flex" alignItems="start" mb={2}>
                      <CheckIcon color="primary" sx={{ mr: 2, fontSize: 20 }} />
                      <Typography fontSize="inherit">{listItemText}</Typography>
                    </Box>
                  );
                })}

              {typeof item === 'string' && (
                <Typography fontSize="inherit">{t(`${translation}.field.${name}.${item}`, fullValues)}</Typography>
              )}
            </Box>
          );
        })}
      </>
    );
  },
);
