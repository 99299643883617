import { useEffect } from 'react';

import { questionnaireConfig } from '@app/questionnaire/configs';
import questionnaireUploadPhotos from '@app/questionnaire/images/card-questionnaire-upload-photos.jpg';
import questionnaireUploadPage from '@app/questionnaire/images/card-questionnaire-upload-page.jpg';

import { applicationConfig } from '../configs';
import uploadImage from '../images/card-application-upload.jpg';

export const useCardImagePreload = () => {
  useEffect(() => {
    const images = [...applicationConfig.surveys, ...questionnaireConfig.surveys].reduce((acc, survey) => {
      if (survey?.meta?.cardImage) {
        acc.push(survey?.meta?.cardImage);
      }

      return acc;
    }, [] as string[]);

    images.push(uploadImage, questionnaireUploadPhotos, questionnaireUploadPage);

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
};
