import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import usePortal from 'react-useportal';

import { Loader } from '@app/ui/loader';
import { Dropdown, DropdownProps } from '@app/ui/dropdown';
import { useQuestionnaireGeneratePdf } from '../hooks/useQuestionnaireDownloadPdf';
import { QuestionnairePdf } from '../constants';

export interface ExportPdfDropdownProps extends DropdownProps {
  readonly questionnaireId: number;
}
export const QuestionnireExportPdfDropdown = ({ questionnaireId, label, ...props }: ExportPdfDropdownProps) => {
  const { t } = useTranslation('common');
  const { generatePdf, isLoading } = useQuestionnaireGeneratePdf();
  const { Portal } = usePortal();

  const exportOption = useMemo(
    () => [
      {
        id: 'surrogates',
        name: (
          <>
            <GroupOutlinedIcon color="secondary" sx={{ mr: 1 }} />
            <span>{t(`questionnaire.export.option.${QuestionnairePdf.surrogates}`)}</span>
          </>
        ),
        onClick: async () => {
          await generatePdf(questionnaireId, QuestionnairePdf.surrogates);
        },
      },
      {
        id: 'agency',
        name: (
          <>
            <ApartmentIcon color="primary" sx={{ mr: 1 }} />
            <span>{t(`questionnaire.export.option.${QuestionnairePdf.agency}`)}</span>
          </>
        ),
        onClick: async () => {
          await generatePdf(questionnaireId, QuestionnairePdf.agency);
        },
      },
    ],
    [],
  );

  return (
    <>
      <Portal>
        <Loader isLoading={isLoading} />
      </Portal>
      <Dropdown label={label} options={exportOption} {...props} />
    </>
  );
};
