import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { QuestionnairePdf, QuestionnaireSurveyEnum } from '../constants';
import cardImage from '../images/card-questionnaire-couple-questions.jpg';

export const coupleQuestions: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.coupleQuestions,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation', 'partner.firstName', 'firstName'],
  },
  visibleIf: ({ isPartnerInformation }) => Boolean(isPartnerInformation),
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.coupleQuestions}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.coupleQuestions}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            variables: {
              time: '5-10',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'storyOfHowYouMet',
      elements: [
        {
          noLabel: true,
          name: 'storyOfHowYouMet',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'spareTimeActivities',
      elements: [
        {
          noLabel: true,
          name: 'spareTimeActivities',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'firstParentDescription',
      elements: [
        {
          noLabel: true,
          name: 'firstParentDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'secondParentDescription',
      elements: [
        {
          noLabel: true,
          name: 'secondParentDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
  ],
};
