import { QuestionnaireDynamicFormConfig } from '../types';
import { acknowledgement } from './acknowledgement';
import { coupleQuestions } from './coupleQuestions';
import { firstIntendedParent } from './firstIntendedParent';
import { pathToParenthood } from './pathToParenthood';
import { secondIntendedParent } from './secondIntendedParent';
import { surrogacyJourney } from './surrogacyJourney';

export const questionnaireConfig: QuestionnaireDynamicFormConfig = {
  pdf: {
    coverPageFields: [
      'highlights',
      'photos.id',
      'photos.name',
      'photos.download.url',
      'avatar.id',
      'avatar.name',
      'avatar.download.url',
    ],
  },
  surveys: [
    firstIntendedParent,
    secondIntendedParent,
    coupleQuestions,
    pathToParenthood,
    surrogacyJourney,
    acknowledgement,
  ],
};
