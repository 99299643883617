import { MouseEvent, useCallback, useMemo } from 'react';
import { useField } from 'formik';

import {
  ToggleButtonProps as MuiToggleButtonProps,
  ToggleButton as MuiToggleButton,
} from '@app/ui/forms/components/ToggleButton';
import { useTranslation } from 'react-i18next';
import { useErrorTranslations } from '@app/formik/hooks/useErrorTranslations';

export interface ToggleButtonProps extends Omit<MuiToggleButtonProps, 'options'> {
  readonly name: string;
  readonly translation?: string;
  readonly withLockIcon?: boolean;
}

export const ToggleButton = ({ name, helperText, translation, ...props }: ToggleButtonProps) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);
  const { t } = useTranslation('common');

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback((event: MouseEvent<HTMLElement>, newValue: string) => {
    newValue && setValue(newValue === 'true');
  }, []);

  const options = useMemo(() => {
    const trans = translation ?? 'general.option';

    return [
      {
        id: 'true',
        name: t(`${trans}.yes`, t('general.option.yes')),
      },
      {
        id: 'false',
        name: t(`${trans}.no`, t('general.option.no')),
      },
    ];
  }, [t, translation]);

  return (
    <MuiToggleButton
      {...props}
      value={String(value)}
      onChange={handleChange}
      options={options}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
