import { useField } from 'formik';

import { CheckboxProps as MuiCheckboxProps, Checkbox as MuiCheckbox } from '@app/ui/forms/components/Checkbox';
import { useErrorTranslations } from '@app/formik/hooks/useErrorTranslations';

export interface CheckboxProps extends MuiCheckboxProps {
  readonly translation?: string;
  readonly name: string;
  readonly withLockIcon?: boolean;
}

export const Checkbox = ({ name, helperText, ...props }: CheckboxProps) => {
  const [{ value, onChange }, { touched, error }] = useField(name);

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  return (
    <MuiCheckbox
      name={name}
      value={value}
      checked={Boolean(value)}
      onChange={onChange}
      {...props}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
