import { QuestionnairePdf } from '@app/questionnaire/constants';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { Document } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfPages } from '@app/application/components/pdf/pages/PdfPages';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { QuestionnairePdfCoverPage } from './pages/QuestionnairePdfCoverPage';

export interface QuestionnairePdfDocumentProps {
  readonly data: Partial<Questionnaire>;
  readonly type: QuestionnairePdf;
}

export const QuestionnairePdfDocument: React.FC<QuestionnairePdfDocumentProps> = ({ data, type }) => {
  const { t } = useTranslation('common');

  const pdfData = useMemo(() => ({ ...data, isPdf: true }), [data]);

  return (
    <Document
      title={`${t(`questionnaire.export.option.${type}`)} - ${t('questionnaire.pdf.title')}`}
      subject={`${t('questionnaire.pdf.subject')}`}
      language="en"
      creator="isc"
      producer="isc"
    >
      <QuestionnairePdfCoverPage data={pdfData} />
      <PdfPages data={pdfData} surveys={questionnaireConfig.surveys} type={type} translation="questionnaire" />
    </Document>
  );
};
