import { ReactNode } from 'react';
import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as lockIcon } from '@app/app/images/icons/icon-lock.svg';

import { Tooltip } from '@app/ui/tooltip';

export interface TextLabelProps {
  readonly label: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
  readonly withLockIcon?: boolean;
}

export const TextLabel = ({ label, description, required, withLockIcon }: TextLabelProps) => {
  return (
    <Box height={24}>
      {label}
      {required && <>&nbsp;*</>}
      {description && (
        <Box sx={{ whiteSpace: 'nowrap' }} component="span">
          &nbsp;
          <Tooltip title={description}>{withLockIcon && <SvgIcon component={lockIcon} />}</Tooltip>
        </Box>
      )}
    </Box>
  );
};
