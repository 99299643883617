import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Questionnaire } from '../types/Questionnaire';
import { QuestionnaireSurveyEnum, QuestionnaireSurveyFlagEnum } from '../constants';
import { QUESTIONNAIRE_UPDATE_SURVEY_FLAG } from '../gql';

export const useQuestionnaireSurveyFlag = ({ id, survey }: { id: number; survey: QuestionnaireSurveyEnum }) => {
  const [questionnaireUpdateSurveyFlag] = useMutation<{
    questionnaireUpdateSurveyFlag: Partial<Questionnaire>;
  }>(QUESTIONNAIRE_UPDATE_SURVEY_FLAG);

  const toggleFlag = useCallback(
    (flag: QuestionnaireSurveyFlagEnum) => {
      questionnaireUpdateSurveyFlag({ variables: { input: { id, survey, flag } } }).catch(e => console.error(e));
    },
    [id, survey],
  );

  return {
    toggleFlag,
  };
};
