import { Routes, Route } from 'react-router-dom';

import { useSignedIn } from '@app/auth';
import { useScrollToTop } from '@app/app/hooks/useScrollToTop';
import { useGoogleTagManager } from '@app/app/hooks/useTagManager';

import { Public } from './_public';
import { Private } from './_private';
import { ChangeEmailConfirmPage } from './change-email-confirm';
import { PasswordCreatePage } from './password-create';
import { AdminSingleUserPdfPage } from './admin/single-user-pdf';
import { SignUpPage } from './sign-up';
import { SignUpParentPage } from './sign-up-parent';
import { SignUpParentConfirmPage } from './sign-up-parent-confirm';

export const App = () => {
  const isSignedIn = useSignedIn();
  useScrollToTop();
  useGoogleTagManager();

  return (
    <Routes>
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/sign-up-parent" element={<SignUpParentPage />} />
      <Route path="/change-email/:token" element={<ChangeEmailConfirmPage />} />
      <Route path="/password-create/:token" element={<PasswordCreatePage />} />
      <Route path="/admin/:entity/:id/pdf/:type" element={<AdminSingleUserPdfPage />} />
      <Route path="/thank-you-parent" element={<SignUpParentConfirmPage />} />
      <Route path="*" element={isSignedIn ? <Private /> : <Public />} />
    </Routes>
  );
};
