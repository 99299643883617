import { ApplicationStatusEnum } from '@app/application/constants';
import { QuestionnaireStatusEnum } from '@app/questionnaire/constants';

export const USER_STATUS_COLOR = {
  [ApplicationStatusEnum.lead as string]: 'info',
  [ApplicationStatusEnum.delivered as string]: 'success',
  [ApplicationStatusEnum.disqualified as string]: 'error',
  [QuestionnaireStatusEnum.applicants as string]: 'info',
  [QuestionnaireStatusEnum.completedIp as string]: 'success',
};

export enum UserRoleEnum {
  admin = 'admin',
  surrogate = 'surrogate',
  parent = 'parent',
}

export const generalInfoItems = ['firstName', 'lastName', 'phone', 'user.email'] as const;
export const questionnaireGeneralInfoItem = ['firstName', 'lastName', 'phone', 'user.email'] as const;
