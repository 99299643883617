import { useField } from 'formik';

import {
  RadioGroupProps as MuiRadioGroupProps,
  RadioGroup as MuiRadioGroup,
} from '@app/ui/forms/components/RadioGroup';
import { useErrorTranslations } from '@app/formik/hooks/useErrorTranslations';

import { WithOptionVisibleIf } from '../../types';
import { useOptionVisibleIf } from '../../hooks/useOptionVisibleIf';

export interface RadioGroupProps extends WithOptionVisibleIf<MuiRadioGroupProps> {
  readonly name: string;
  readonly withLockIcon?: boolean;
}

export const RadioGroup = ({ name, optionVisibleIf, options, ...props }: RadioGroupProps) => {
  const [{ value, onChange }, { touched, error }] = useField(name);

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const filteredOptions = useOptionVisibleIf(options, optionVisibleIf);

  return (
    <MuiRadioGroup
      name={name}
      value={value || null}
      onChange={onChange}
      error={isError}
      helperText={isError ? errorHelperText : ''}
      {...props}
      options={filteredOptions}
    />
  );
};
