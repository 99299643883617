import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth, useAuthSignOutSubscriber } from '@app/auth';
import { Loader } from '@app/ui/loader';
import { MainLayout } from '@app/layouts';
import { useCardImagePreload } from '@app/application/hooks/useCardImagePreload';

import { SurrogateApplicationPage } from './surrogate-application';
import { UserFormPage } from './surrogate-application/user-form';
import { UploadPhotosPage } from './surrogate-application/upload-photos';
import { AdminUsersPage } from './admin/users';
import { AdminSingleUserPage } from './admin/single-user';
import { AdminSingleUserFormPage } from './admin/single-user-form';
import { SingleParentQuestionnairePage } from './parent/single-parent-form';
import { ParentQuestionnairePage } from './parent';
import { ProfilePage } from './profile';
import { ParentUploadPhotosPage } from './parent/questionnaire-upload-photos';

export const Private = () => {
  const { user, isAdmin, isParent } = useAuth();

  useAuthSignOutSubscriber();
  useCardImagePreload();

  if (!user) {
    return <Loader isLoading />;
  }

  return (
    <MainLayout>
      <Routes>
        {isAdmin ? (
          <>
            {/* Admin Routes */}
            <Route path="/admin/:entity/" element={<AdminUsersPage />} />
            <Route path="/admin/:entity/:id" element={<AdminSingleUserPage />} />
            <Route path="/admin/:entity/:id/:survey" element={<AdminSingleUserFormPage />} />
            <Route path="*" element={<Navigate to="/admin/surrogates" />} />
          </>
        ) : isParent ? (
          <>
            {/* Parent Routes */}
            <Route path="/parent-questionnaire" element={<ParentQuestionnairePage />} />
            <Route path="/parent-questionnaire/upload-photos" element={<ParentUploadPhotosPage />} />
            <Route path="/parent-questionnaire/:survey" element={<SingleParentQuestionnairePage />} />
            <Route path="*" element={<Navigate to="/parent-questionnaire" />} />
          </>
        ) : (
          <>
            {/* Surrogate Routes */}
            <Route path="/surrogate-application" element={<SurrogateApplicationPage />} />
            <Route path="/surrogate-application/upload-photos" element={<UploadPhotosPage />} />
            <Route path="/surrogate-application/:survey" element={<UserFormPage />} />
            <Route path="*" element={<Navigate to="/surrogate-application" />} />
          </>
        )}
        {/* General Private Routes */}
        <Route path="/my-account" element={<ProfilePage />} />
      </Routes>
    </MainLayout>
  );
};
