import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { LinearProgress } from '@app/ui/progress';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SurveyItem } from '@app/application/components/item/SurveyItem';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { useMemo } from 'react';
import { calculateSurveyProgress, isVisible } from '@app/dynamic/utils';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { QuestionnaireStatusEnum } from '@app/questionnaire/constants';

import { paramCase } from 'change-case';
import { getProgressStatusConfig } from '@app/shared/utils';
import { FinishAndSubmit } from '@app/shared/components/buttons/FinishAndSubmit';

export interface QuestionnaireListProps {
  readonly item: Questionnaire;
  readonly isAdmin?: boolean;
  readonly onSubmit?: () => void;
  readonly onClick: (survey: string) => void;
}

export const QuestionnaireList = ({ item, isAdmin, onClick, onSubmit }: QuestionnaireListProps) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();

  const questionnaireProgress = useMemo(() => calculateSurveyProgress(questionnaireConfig.surveys, item), [item]);

  const surveysConfig = useMemo(() => questionnaireConfig.surveys.filter(survey => isVisible(survey, item)), [item]);

  const notFinished = questionnaireProgress.progressTotal !== 100;

  const isSubmittedQuestionnaire = item.status !== QuestionnaireStatusEnum.applicants && !isAdmin;

  return (
    <>
      <Box
        sx={{
          display: {
            sm: 'flex',
          },
          alignItems: 'center',
          mb: { xs: 3, md: 4 },
        }}
      >
        <Typography
          variant={isMobile ? 'h2' : 'h3'}
          component="h3"
          sx={{
            whiteSpace: 'nowrap',
            mr: {
              sm: 3,
              md: 5,
            },
            mb: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          {t('user.page.single.currentProgress')}
        </Typography>
        <LinearProgress value={questionnaireProgress.progressTotal} />
      </Box>

      <Paper
        sx={{
          borderRadius: '20px',
        }}
        variant="outlined"
        elevation={0}
      >
        {surveysConfig.map(survey => {
          const progressConfig = questionnaireProgress.populatedSurveyCountMap[survey.name];

          const { status, icon, iconButton, color } = getProgressStatusConfig(
            progressConfig.populatedPageCount,
            progressConfig.pageCount,
          );

          const handleSurvey = () => onClick(paramCase(survey.name));

          return (
            <SurveyItem
              key={survey.name}
              surveyLabel={t(`questionnaire.field.survey.option.${survey.name}.name`, item)}
              progressConfig={progressConfig}
              statusLabel={t(`questionnaire.form.status.${status}`)}
              color={color}
              icon={icon}
              iconButton={iconButton}
              isAdmin={isAdmin || false}
              isSubmitted={isSubmittedQuestionnaire}
              isMobile={isMobile}
              onClick={handleSurvey}
            />
          );
        })}
      </Paper>

      {!isAdmin && item.status === QuestionnaireStatusEnum.applicants && (
        <FinishAndSubmit
          onSubmit={onSubmit}
          notFinished={notFinished}
          tooltipText={t('application.button.finishAndSubmit.description')}
          buttonText={t('questionnaire.button.finishAndSubmit.name')}
        />
      )}
    </>
  );
};
