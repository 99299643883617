import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { palette } from '@app/app/configs/theme';

import { mainStyles } from '../styles';
import logoMain from '../images/logo-main@2x.png';
import footerDecorate from '../images/img-footer-decorate@2x.png';

export interface PdfPagesTemplateProps {
  readonly userName: string;
  readonly children: ReactNode;
  readonly type: 'questionnaire' | 'application';
}

export const PdfPagesTemplate = ({ userName, children, type = 'application' }: PdfPagesTemplateProps) => {
  const { t } = useTranslation('common');

  return (
    <Page style={[mainStyles.page, styles.page]}>
      <View fixed style={styles.pageFixed}>
        <View
          style={[
            styles.pageDecorator,
            type === 'application' ? styles.pageDecoratorPrimary : styles.pageDecoratorSecondary,
          ]}
        />
        <View style={[styles.pageIndent, styles.pageTopIndent]} />
        <View style={[styles.pageIndent, styles.pageBottomIndent]} />

        <View style={[mainStyles.header, styles.header]}>
          <Text>
            {type === 'application' && <>{t('application.pdf.candidate')}:&nbsp;</>}
            <Text style={mainStyles.bold}>{userName}</Text>
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${t('application.pdf.page')} ${pageNumber - 1}/${totalPages - 1}`}
          />
        </View>

        <View style={[mainStyles.footer, styles.footer]}>
          <Image style={styles.footerImageDecorate} src={footerDecorate} />
          <Image style={styles.footerLogo} src={logoMain} />
        </View>
      </View>

      <View style={[mainStyles.content, styles.content]}>
        <View style={styles.contentCard}>{children}</View>
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: 66,
    paddingBottom: 76,
  },
  pageFixed: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: 4,
  },
  pageDecoratorSecondary: {
    backgroundColor: palette.secondary.main,
  },
  pageDecoratorPrimary: {
    backgroundColor: palette.primary.main,
  },
  pageDecorator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 3,
    width: '55%',
  },
  pageIndent: {
    height: 23,
    left: 30,
    right: 30,
    backgroundColor: '#FFF',
    position: 'absolute',
    zIndex: 2,
  },
  pageTopIndent: {
    top: 45,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  pageBottomIndent: {
    bottom: 56,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  header: {
    color: '#FFF',
    height: 45,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageNumber: {
    color: palette.text.secondary,
    fontWeight: 600,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentCard: {
    backgroundColor: '#FFF',
    flex: 1,
    paddingLeft: 23,
  },
  footer: {
    height: 56,
    overflow: 'hidden',
  },
  footerLogo: {
    width: 81,
    marginLeft: 'auto',
  },
  footerImageDecorate: {
    width: 120,
    height: 82,
    position: 'absolute',
    bottom: 0,
    left: 110,
  },
});
