import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';

import {
  CheckboxGroupProps as MuiCheckboxGroupProps,
  CheckboxGroup as MuiCheckboxGroup,
} from '@app/ui/forms/components/CheckboxGroup';
import { useErrorTranslations } from '@app/formik/hooks/useErrorTranslations';

export interface CheckboxGroupProps extends Omit<MuiCheckboxGroupProps, 'value'> {
  readonly name: string;
  readonly none?: string;
  readonly withLockIcon?: boolean;
}

export const CheckboxGroup = ({ name, helperText, none, ...props }: CheckboxGroupProps) => {
  const [{ value, onChange }, { touched, error }, { setValue }] = useField(name);
  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const oldValue = value || [];

      if (
        (none && none === event.target.value && !oldValue.includes(none)) ||
        (none !== event.target.value && oldValue.includes(none))
      ) {
        setValue([event.target.value]);
      } else {
        onChange(event);
      }
    },
    [value, none],
  );

  return (
    <MuiCheckboxGroup
      name={name}
      value={value}
      onChange={handleChange}
      {...props}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
