import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@app/ui/loader';
import { error, success } from '@app/snackbars';
import { useMutation } from '@apollo/client';
import { User } from '@app/user/types/User';
import { AUTH_CHANGE_EMAIL_CONFIRM } from '@app/auth/gql';
import { useAuthActions } from '@app/auth';

export const ChangeEmailConfirm = () => {
  const { t } = useTranslation('common');

  const { signOut } = useAuthActions();
  const [changeEmailConfirm] = useMutation<{ authChangeEmailConfirm: User }>(AUTH_CHANGE_EMAIL_CONFIRM);

  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          await changeEmailConfirm({ variables: { input: { token } } });

          try {
            await signOut();
          } finally {
            navigate('/sign-in');

            success(t('auth.page.changeEmailConfirm.successMessage'));
          }
        } catch (e) {
          navigate('/');
          error((e as Error).message || t('general.error.somethingWentWrong'));
        }
      })();
    }
  }, [token]);

  return <Loader isLoading />;
};
