import { Checkbox } from '@app/formik';
import { Link } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

interface TermsAndMessageConsentCheckboxesProps {
  termsLabel: string;
  messageConsentLabel: string;
  termsName?: string;
  messageConsentName?: string;
  termsRequired?: boolean;
  messageConsentRequired?: boolean;
}

export const TermsAndMessageConsentCheckboxes: React.FC<TermsAndMessageConsentCheckboxesProps> = ({
  termsLabel,
  messageConsentLabel,
  termsName = 'terms',
  messageConsentName = 'messageConsent',
  termsRequired = true,
  messageConsentRequired = false,
}) => {
  return (
    <>
      <Checkbox
        label={
          <Trans
            i18nKey={termsLabel}
            components={{
              terms: <Link href="https://www.internationalsurrogacycenter.com/terms-of-use/" target="_blank" />,
              policy: <Link href="https://www.internationalsurrogacycenter.com/privacy-policy/" target="_blank" />,
            }}
          />
        }
        name={termsName}
        required={termsRequired}
      />

      <Checkbox label={messageConsentLabel} name={messageConsentName} required={messageConsentRequired} />
    </>
  );
};
