import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { Label } from '@app/ui/label';

export interface DynamicFormWizardFinishProps {
  readonly onClick: () => void;
  readonly steps: number;
  readonly survey: string;
  readonly data: Record<any, any>;
  readonly translation: string;
}

export const DynamicFormWizardFinish = ({
  onClick,
  survey,
  data,
  steps,
  translation,
}: DynamicFormWizardFinishProps) => {
  const { t } = useTranslation('common');

  const surveyTitle = t(`${translation}.survey.option.${survey}.name`, data);

  const fullData = { ...data, survey: surveyTitle };

  return (
    <>
      <Box mb={3}>
        <Label
          icon={<CheckIcon fontSize="inherit" />}
          text={
            <>
              {t(`${translation}.form.status.completed`, data)} {steps}/{steps}
            </>
          }
          color="success"
          rounded
        />
      </Box>
      <Typography variant="h3" mb={3}>
        {t(`${translation}.${survey}.finish.title`, t(`${translation}.wizard.finish.title`, fullData), fullData)}
      </Typography>
      <Typography>
        {t(
          `${translation}.${survey}.finish.description`,
          t(`${translation}.wizard.finish.description`, fullData),
          fullData,
        )}
      </Typography>
      <Box
        sx={{
          display: { sm: 'flex' },
          justifyContent: 'flex-end',
          mt: 3,
        }}
      >
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
          onClick={onClick}
          variant="contained"
          color="primary"
        >
          {t(`${translation}.${survey}.finish.submit`, t('application.button.continueNextSection.name'))}
        </Button>
      </Box>
    </>
  );
};
