import { AdminApplicationForm } from '@app/application/components/AdminApplicationForm';
import { AdminQuestionnaireForm } from '@app/questionnaire/components/AdminQuestionnaireForm';
import { useParams } from 'react-router-dom';

export const AdminSingleUserFormPage = () => {
  const { entity } = useParams<{ id?: string; survey: string; entity: 'surrogates' | 'parent' }>();

  const isSurrogates = entity === 'surrogates';

  if (isSurrogates) {
    return <AdminApplicationForm />;
  }

  return <AdminQuestionnaireForm />;
};
