import { Tooltip, Button } from '@mui/material';
import { Box } from '@mui/system';

export interface FinishAndSubmitProps {
  notFinished: boolean;
  onSubmit: (() => void) | undefined;
  buttonText: string;
  tooltipText: string;
}

export const FinishAndSubmit: React.FC<FinishAndSubmitProps> = ({ notFinished, onSubmit, buttonText, tooltipText }) => {
  return (
    <Box
      sx={{
        display: { sm: 'flex' },
        justifyContent: 'flex-end',
        borderTop: theme => `1px solid ${theme.palette.grey[500]}`,
        pt: { xs: 3, sm: 4 },
        mt: { xs: 3, sm: 4 },
      }}
    >
      <Tooltip sx={{ display: 'block' }} title={notFinished ? tooltipText : null}>
        <div>
          <Button
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={notFinished}
          >
            {buttonText}
          </Button>
        </div>
      </Tooltip>
    </Box>
  );
};
