import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import imgCard from '../images/img-card-sign-up-parent-confirm.png';

export const SignUpParentConfirm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <>
      <Loader isLoading={false} />

      <Card img={imgCard}>
        <Typography variant="h1">{t('auth.page.signUpParentConfirm.title')}</Typography>

        <Grid container gap={2} mt={{ xs: 2, md: 3 }}>
          <Box>
            <Typography variant="subtitle1">{t('auth.page.signUpParentConfirm.text1')}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1">{t('auth.page.signUpParentConfirm.text2')}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1">{t('auth.page.signUpParentConfirm.text3')}</Typography>
          </Box>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => navigate('/parent-questionnaire')}
          >
            {t('auth.page.signUpParentConfirm.button')}
          </Button>
        </Box>
      </Card>
    </>
  );
};
