import { AdminApplicationItem } from '@app/application/components/item/AdminApplicationItem';
import { AdminQuestionnaireItem } from '@app/questionnaire/components/item/AdminQuestionnaireItem';
import { useParams } from 'react-router-dom';

export const AdminSingleUserPage = () => {
  const { entity } = useParams<{ id?: string; entity: 'surrogates' | 'parent' }>();
  const isSurrogates = entity === 'surrogates';

  if (isSurrogates) {
    return <AdminApplicationItem />;
  }

  return <AdminQuestionnaireItem />;
};
