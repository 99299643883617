import { SignUpParent } from '@app/auth/components/SignUpParent';
import { LightLayout } from '@app/layouts';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const SignUpParentPage = () => {
  return (
    <LightLayout>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}>
        <SignUpParent />
      </GoogleReCaptchaProvider>
    </LightLayout>
  );
};
