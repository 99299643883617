import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import { Tooltip } from '@app/ui/tooltip';

import { ReactComponent as lockIcon } from '@app/app/images/icons/icon-lock.svg';
import { extend } from '../hocs/extend';
import { visibleIf } from '../hocs/visibleIf';
import { DynamicFormElementWidthMode } from '../constants';
import { DynamicFormElement, DynamicElementProps } from './DynamicFormElement';
import { DynamicFormConfigPage, DynamicFormComponentProps } from '../types';

export interface DynamicFormPageProps extends DynamicFormComponentProps<DynamicFormConfigPage> {
  readonly values: Record<any, any>;
  readonly isWizard?: boolean;
}

const DynamicFormElementGridItem = ({ config, translation, values, ...rest }: DynamicElementProps) => (
  <>
    <Grid item xs={12} sm={config?.widthMode === DynamicFormElementWidthMode.full ? 12 : 6}>
      <DynamicFormElement translation={translation} config={config} values={values} {...rest} />
    </Grid>
    {config?.widthMode === DynamicFormElementWidthMode.half && <Grid item sm={6} />}
  </>
);

const DynamicFormElementGridItemVisibleIf = visibleIf<DynamicElementProps>(DynamicFormElementGridItem);

export const DynamicFormPage = ({
  config,
  translation,
  values,
  isWizard,
  readOnly,
  disabled,
}: DynamicFormPageProps) => {
  const { t } = useTranslation('common');

  const isOneElement = config.elements.length === 1;

  const currentTranslation = config?.translation ?? translation;

  const fullValues = { ...config.variables, ...values };

  const alias = config?.alias || config.name;

  const tooltipText = config?.withLockIcon
    ? t(`questionnaire.tooltip.notShared.description`)
    : t(`${currentTranslation}.${alias}.description`);

  return (
    <Box mb={4}>
      {!config?.noLabel && (
        <Typography variant={isWizard ? 'h3' : 'h4'} mb={2} sx={{ position: 'relative', zIndex: 1 }}>
          <Trans>
            {t(
              `${currentTranslation}.${alias}.subtitle`,
              t(`${currentTranslation}.${alias}.label`, fullValues),
              fullValues,
            )}
          </Trans>
          {(config?.showDescription || config?.withLockIcon) && (
            <Box sx={{ whiteSpace: 'nowrap' }} component="span">
              &nbsp;
              <Tooltip title={tooltipText}>{config.withLockIcon && <SvgIcon component={lockIcon} />}</Tooltip>
            </Box>
          )}
        </Typography>
      )}

      {isOneElement && config.elements[0].widthMode === DynamicFormElementWidthMode.full ? (
        <DynamicFormElement
          readOnly={readOnly}
          disabled={disabled}
          translation={currentTranslation}
          config={config.elements[0]}
          values={values}
        />
      ) : (
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {config.elements.map(element => {
            const DynamicFormPageComponent = element.visibleIf
              ? DynamicFormElementGridItemVisibleIf
              : DynamicFormElementGridItem;

            return (
              <DynamicFormPageComponent
                key={element.name}
                readOnly={readOnly}
                disabled={disabled}
                translation={currentTranslation}
                config={element}
                values={values}
              />
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export const DynamicFormPageExtended = extend<DynamicFormPageProps>(DynamicFormPage);
