import { IconButtonProps } from '@mui/material';
import { ElementType } from 'react';
import { ReactComponent as docTimeIcon } from '@app/app/images/icons/icon-doc-time.svg';
import { ReactComponent as updateIcon } from '@app/app/images/icons/icon-update.svg';
import { ReactComponent as checkIcon } from '@app/app/images/icons/icon-check.svg';
import { ReactComponent as playIcon } from '@app/app/images/icons/icon-play.svg';
import { ProgressStatusEnum } from '../types';

export type ProgressStatusConfig = {
  color: IconButtonProps['color'];
  status: ProgressStatusEnum;
  icon: ElementType;
  iconButton: ElementType;
};

export const getProgressStatusConfig = (progress: number, max: number): ProgressStatusConfig => {
  if (progress === 0) {
    return {
      status: ProgressStatusEnum.notStarted,
      color: 'secondary',
      icon: docTimeIcon,
      iconButton: playIcon,
    };
  }

  if (progress === max) {
    return {
      status: ProgressStatusEnum.completed,
      color: 'success',
      icon: checkIcon,
      iconButton: checkIcon,
    };
  }

  return {
    status: ProgressStatusEnum.inProgress,
    color: 'warning',
    icon: updateIcon,
    iconButton: updateIcon,
  };
};
